import VueRouter from "vue-router";
import Vue from "vue";
import Login from "@/pages/Login";
import Main from "@/pages/Main";
import Dashboard from "@/pages/Dashboard";
import Customers from "@/pages/Customers";
import Contacts from "@/pages/Contacts";
import PotentialCustomers from "@/pages/PotentialCustomers";
import Reports from "@/pages/Reports";
import Users from "@/pages/Users";
import Settings from "@/pages/Settings";
import AccountDetails from "@/pages/AccountDetails";
import UserRights from "@/pages/UserRights";
import Lock from "@/pages/Lock";
import PriceCategories from "@/pages/PriceCategories";
import Teams from "@/pages/Teams"
Vue.use(VueRouter);

const routes = [
  { path: "*", redirect: { name: "Dashboard" } },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/lock",
    name: "Lock",
    component: Lock,
    meta: {
      permission: "default",
    },
  },
  {
    path: "/",
    name: "Main",
    component: Main,
    redirect: "/dashboard",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          permission: "default",
        },
      },
      {
        path: "/customers",
        name: "Customers",
        component: Customers,
        meta: {
          permission: "default",
        },
      },
      {
        path: "/contacts",
        name: "Contacts",
        component: Contacts,
        meta: {
          permission: "default",
        },
      },
      {
        path: "/potential-customers",
        name: "PotentialCustomers",
        component: PotentialCustomers,
        meta: {
          permission: "default",
        },
      },
      {
        path: "/reports",
        name: "Reports",
        component: Reports,
        meta: {
          permission: "default",
        },
      },
      {
        path: "/users",
        name: "Users",
        component: Users,
        meta: {
          permission: "user_access",
        },
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
        meta: {
          permission: "default",
        },
      },
      {
        path: "/account-details/:entity/:id",
        name: "AccountDetails",
        component: AccountDetails,
        meta: {
          permission: "default",
        },
      },
      {
        path: "/user-rights",
        name: "UserRights",
        component: UserRights,
        meta: {
          permission: "user_management_access",
        },
      },
      {
        path: "/price-categories",
        name: "PriceCategories",
        component: PriceCategories,
        meta: {
          permission: "default",
        },
      },
      {
        path: "/teams",
        name: "Teams",
        component: Teams,
        meta: {
          permission: "default",
        },
      },
    ],
  },
];

export default new VueRouter({
  routes,
  mode: "history",
});
