<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--provision"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="add__form">
          <transition name="fade" mode="out-in">
            <div class="add__content">
              <div class="modal__provision">
                <div class="modal__provision-title">Policy Provision</div>
                <div class="modal__provision-container">
                  <div class="modal__provision-row">
                    <div class="form__field form__field--margin">
                      <div class="modal__provision-label">Customer:</div>
                      <div class="modal__provision-data">
                        {{ parseCustomerName(customer) || statics.empty }}
                      </div>
                    </div>
                    <div class="form__field">
                      <div class="modal__provision-label">Company:</div>
                      <div class="modal__provision-data">
                        {{ serviceData.company.full_name || statics.empty }}
                      </div>
                    </div>
                    <div class="form__field form__field--margin">
                      <div class="modal__provision-label">Service:</div>
                      <div class="modal__provision-data">
                        {{
                          selectedPolicyService.service[`long_${language}`] ||
                            statics.empty
                        }}
                      </div>
                    </div>
                    <div class="form__field">
                      <div class="modal__provision-label">Policy Nr:</div>
                      <div class="modal__provision-data">
                        {{ serviceData.policy_number || statics.empty }}
                      </div>
                    </div>
                    <div class="form__field form__field--margin">
                      <div class="modal__provision-label">Agent:</div>
                      <div class="modal__provision-data">
                        {{
                          `${serviceData.agent.first_name} ${serviceData.agent.last_name}`
                        }}
                      </div>
                    </div>
                    <div class="form__field">
                      <div class="modal__provision-label">Date:</div>
                      <div class="modal__provision-data">
                        {{ `${startDate} - ${endDate}` }}
                      </div>
                    </div>
                    <div class="form__field form__field--supervisors">
                      <div class="modal__provision-label">Supervisor:</div>
                      <div
                        class="modal__provision-data"
                        v-for="(supervisor, index) in supervisors"
                        :key="supervisor"
                      >
                        <div
                          class="modal__provision-arrow"
                          v-if="index !== 0 && index !== supervisors.length"
                        ></div>
                        {{ supervisor }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__provision-container">
                  <div class="modal__provision-row">
                    <div class="form__field form__field--margin">
                      <div class="modal__provision-label">Provision:</div>
                      <span
                        class="editable"
                        @click="paid ? null : (showTotalEditer = true)"
                        ><PopupEditer
                          v-if="showTotalEditer"
                          @togglePopup="showTotalEditer = $event"
                          :selected="total"
                          @submit="openSubmitter($event)"
                        />{{ total }} .- CHF</span
                      >
                    </div>
                    <div
                      class="form__field form__field--status"
                      :class="`form__field--${paid ? 'paid' : 'unpaid'}`"
                    >
                      <div class="modal__provision-label">Status:</div>
                      <div class="modal__provision-data">
                        {{ paid ? "Paid" : "Unpaid" }}
                      </div>
                    </div>
                  </div>
                  <div class="modal__provision-row">
                    <div
                      class="form__field form__field--margin form__field--empty"
                    ></div>
                    <button
                      v-if="showMakePaymentButton && !paid"
                      type="button"
                      class="form__field button button--default"
                      @click="updateStatus()"
                    >
                      Make Payment
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="isRequesting"
                class="button modal__button modal__button--submit button--default button--loading"
              ></div>
              <button
                v-if="showButton && !isRequesting"
                @click="updateTotal()"
                class="button modal__button modal__button--submit button--default"
              >
                Save
              </button>
            </div>
          </transition>
        </div>
      </div>
      <div class="modal__submit" v-if="showSubmitter">
        <div class="modal__submit-panel">
          <div class="modal__submit-icon"></div>
          <div class="modal__submit-border"></div>
          <div class="modal__submit-container">
            <div class="modal__submit-text">
              Are you sure you want to make Provision Changes?
            </div>
            <div class="modal__submit-buttons">
              <button class="modal__submit-button" @click="applyTotal()">
                Yes
              </button>
              <button class="modal__submit-button" @click="closeSubmitter()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PopupEditer from "@/partials/PopupEditer";
import { parseCustomerName } from "@/helpers/entityParsers";
import { formatDisplayDate } from "@/helpers/dateFormatters";
import statics from "@/statics";
export default {
  name: "PolicyProvisionModal",

  components: {
    PopupEditer,
  },

  data() {
    return {
      statics,
      showButton: false,
      showTotalEditer: false,
      paid: 0,
      preTotal: 0,
      total: 0,
      previousPrice: 0,
      showSubmitter: false,
      isRequesting: false,
      parseCustomerName,
      formatDisplayDate,
      showMakePaymentButton: false,
    };
  },

  watch: {
    total(total) {
      if (total !== this.previousPrice) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
  },

  computed: {
    ...mapGetters(["selectedPolicyService", "customer", "language"]),

    startDate() {
      return this.selectedPolicyService.start_date
        ? formatDisplayDate(this.selectedPolicyService.start_date)
        : this.statics.empty;
    },

    endDate() {
      return this.selectedPolicyService.end_date
        ? formatDisplayDate(this.selectedPolicyService.end_date)
        : this.statics.empty;
    },

    serviceData() {
      if (this.customer.policies.length) {
        return this.customer.policies.find(
          (policy) => policy.id === this.selectedPolicyService.policy_id
        );
      }
      return null;
    },

    supervisors() {
      if (this.serviceData.agent.supervisors && this.serviceData.agent.supervisors.length) {
        return this.serviceData.agent.supervisors.map(
          (supervisor) =>
            `${supervisor.first_name ||
              this.statics.empty} ${supervisor.last_name || this.statics.empty}`
        );
      }
      return this.statics.empty;
    },
  },

  mounted() {
    if (this.selectedPolicyService.policy_service_payment) {
      this.paid = this.selectedPolicyService.policy_service_payment.paid;
      this.total = this.selectedPolicyService.policy_service_payment.total;
    }
    this.previousPrice = this.total;

    if (this.total && !this.paid) {
      this.showMakePaymentButton = true;
    }
  },

  destroyed() {
    this.$store.commit("setSelectedPolicyService", {});
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowPolicyProvisionModal", false);
    },

    openSubmitter(total) {
      this.showSubmitter = true;
      this.preTotal = total;
    },

    applyTotal() {
      this.total = this.preTotal;
      this.showSubmitter = false;
    },

    closeSubmitter() {
      this.preTotal = 0;
      this.showSubmitter = false;
    },

    async updateTotal() {
      this.isRequesting = true;
      try {
        let {
          data: { data },
        } = await this.$store.dispatch("changePolicyServicePaymentPrice", {
          policyServiceId: this.selectedPolicyService.policy_service_payment
            ? this.selectedPolicyService.policy_service_payment.id
            : this.selectedPolicyService.id,
          total: Number(this.total),
          state: this.selectedPolicyService.policy_service_payment
            ? "update"
            : "create",
        });

        this.total = data.total;
        this.previousPrice = this.total;

        this.$store.commit("setSelectedPolicyService", {
          ...this.selectedPolicyService,
          policy_service_payment: data,
        });

        await this.$store.dispatch("getGlobalCustomer", this.customer.id);
        this.showMakePaymentButton = true;
      } catch (err) {
        console.error(err);
        this.isRequesting = false;
      }
      this.isRequesting = false;
      this.showButton = false;
    },

    async updateStatus() {
      try {
        const {
          data: { data },
        } = await this.$store.dispatch("changePolicyServicePaidState", {
          policyServiceId: this.selectedPolicyService.policy_service_payment.id,
          paid: !this.paid,
        });
        this.paid = data.paid;
        this.$store.commit("setSelectedPolicyService", {
          ...this.selectedPolicyService,
          policy_service_payment: data,
        });

        await this.$store.dispatch("getGlobalCustomer", this.customer.id);
        this.showMakePaymentButton = false;
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.add {
  &__form {
    width: 100% !important;
    height: initial !important;
  }
}

.modal {
  &__provision {
    padding: 28px 0;

    &-title {
      font-size: var(--medium);
    }

    &-title,
    &-label {
      color: var(--text-2);
    }

    &-label {
      margin-right: 10px;
    }

    &-data {
      color: var(--text-9);
      font-family: var(--muli-text-semi-bold);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 335px;
      display: flex;
    }

    &-container {
      margin-top: 25px;
      padding-top: 15px;
      border-top: 1px solid var(--border-10);
    }

    &-row {
      display: flex;
      flex-wrap: wrap;
      padding: 0 50px;

      .form__field {
        width: calc(50% - 10px);
        background: var(--bg-23);
        padding: 15px;
        border-radius: var(--radius-1);
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        &--margin {
          margin-right: 20px;
        }

        &--status {
          cursor: pointer;
        }

        &--paid {
          background: var(--bg-25);
          border: 1px solid var(--border-2);
          .modal__provision-data {
            color: var(--text-5);
          }
        }

        &--unpaid {
          background: var(--bg-24);
          .modal__provision-data {
            color: var(--text-12);
          }
        }

        &--supervisors {
          width: 100%;
        }

        &--empty {
          background: 0;
        }
      }

      .button {
        background-color: var(--bg-2);
        justify-content: center;
        font-family: var(--muli-text-regular);

        &:hover {
          background-color: var(--hover-1);
        }
      }
    }

    &-arrow {
      background: url("../assets/icons/default/next_arrow.svg");
      height: 16px;
      width: 16px;
      margin: 0 10px;
    }
  }

  &__submit {
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--modal-bg-2);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    &-panel {
      width: 370px;
      padding: 9px 15px;
      background: var(--text-5);
      border-radius: var(--radius-1);
      display: flex;
      align-items: center;
    }

    &-icon {
      background: url("../assets/icons/default/question_icon.svg");
      min-width: 30px;
      height: 30px;
    }

    &-border {
      width: 1px;
      height: 70px;
      margin: 0 15px;
      background: var(--border-16);
    }

    &-container {
      flex-direction: column;
      align-items: flex-start;
    }

    &-buttons,
    &-container {
      display: flex;
      justify-content: center;
    }

    &-button {
      border-radius: var(--radius-1);
      box-shadow: 0px 3px 6px var(--shadow-3);
      color: var(--text-2);
      padding: 5px 0 5px 0;
      width: 100px;
      transition: all 0.3s ease;

      &:hover {
        color: var(--text-5);
        box-shadow: none;
      }

      &:first-child {
        margin-right: 50px;
      }
    }

    &-text {
      color: var(--text-1);
      margin-bottom: 15px;
    }
  }
}
</style>
