<template>
  <div class="policies">
    <div class="table">
      <div class="table__header box box--default">
        <div class="table__label table__label--small  ">
          Doc typ
        </div>
      
        <div class="table__label table__label--medium">
          File
        </div>

<!--        <div class="table__label table__label&#45;&#45;medium">

        </div>-->
        <div class="table__label table__label--small table__label--last"></div>


      </div>

      <div class="table__body">
        <Loading v-if="detailsLoading" />
        <template v-else>
          <div v-if="!mortgageDocuments.length" class="table__no-data">
            <NoData :type="'mortgages'" />
          </div>
          <template v-else>
            <MortgageDocument
                v-for="(document, index) in mortgageDocuments"
                :key="document.id"
                :document="document"
                :updateFiles="updateFiles"
                :index="index"
            />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import NoData from "@/partials/NoData";
import Loading from "@/partials/Loading";
import MortgageDocument from "@/components/AccountDetails/MortgageDocument";

export default {
  name: "MortgageDocumentTable",

  components: {
    MortgageDocument,
    NoData,
    Loading,


  },

  props: {
    mortgageDocuments: {
      required: true,
      type: Array,
    },

    detailsLoading: {
      required: true,
      type: Boolean,
    },
  },
  methods : {
    async updateFiles() {
      const { id } = this.$route.params;
      await this.$store.dispatch("getGlobalCustomer", id);


      this.$store.commit("setMortgageDocuments", [
        ...this.customer.mortgage_documents.documents,
      ]);
    },
  }
};
</script>

<style scoped lang="scss">

</style>
