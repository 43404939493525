<template>
  <div class="notifications">
    <div
      class="notifications__notifications"
      ref="notifications"
      tabindex="-1"
      @focus="toggleNotification(true)"
      :class="{ 'notifications__notifications--show': showNotification }"
    >
      <Notification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      />
    </div>
    <div
      class="notifications__underlay"
      v-if="showNotification"
      @click="toggleNotification(false)"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Notification from "@/components/Notification";
export default {
  name: "Notifications",

  components: {
    Notification,
  },

  props: {
    showNotification: {
      required: true,
      type: Boolean,
    },

    toggleNotification: {
      required: true,
      type: Function,
    },
  },

  watch: {
    showNotification(state) {
      if (state) {
        this.$refs.notifications.focus({ preventScroll: true });
      }
    },
  },

  computed: {
    ...mapGetters(["notifications"]),
  },

  methods: {
    async readNotification(notificationId) {
      await this.$store.dispatch("readNotification", notificationId);
      await this.$store.dispatch("getNotifications");
    },
  },
};
</script>

<style scoped lang="scss">
.notifications {
  &__notifications {
    position: absolute;
    z-index: 9;
    background: var(--bg-1);
    right: 0;
    width: 535px;
    height: calc(100% - 80px);
    bottom: 10px;
    transform: translateX(100%);
    transition: transform 0.2s ease;
    box-shadow: 0px 3px 10px var(--shadow-6);
    border-top-left-radius: var(--radius-1);
    border-bottom-left-radius: var(--radius-1);
    padding: 40px 20px;
    overflow: auto;

    &--show {
      transform: translateX(0);
    }
  }

  &__underlay {
    position: absolute;
    z-index: 8;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
</style>
