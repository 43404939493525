<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel modal__panel--service modal__panel-mortgage">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--policy"></div>
      <div class="modal__back" @click="goBack()"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">
              {{ $t(`addMortgageModal.${"title"}`) }}
            </div>
          </div>
        </div>

        <form class="add__form add__form--service">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <div class="policy">
                <div class="policy__form">
                  <div class="policy__inputs">
                    <div class="policy__column">
                      <div class="form__field">
                        <VSelect
                          v-if="customerMembers.length"
                          class="select select--default"
                          :options="customerMembers"
                          :reduce="(member) => member.id"
                          :getOptionLabel="
                            (member) => parseCustomerName(member)
                          "
                          v-model="mortgage.customerId"
                        />
                        <div
                          v-else
                          class="input input--default input--readonly"
                        >
                          {{ parseCustomerName(customerDetails) }}
                        </div>
                      </div>
                      <!-- <div class="form__field">
                        <div class="mandat">
                          <label class="mandat__title" for="mandatCheckbox"
                            >Makler Mandat</label
                          >
                          <div class="checkbox">
                            <input
                              type="checkbox"
                              id="mandatCheckbox"
                              class="checkbox__checkbox"
                              v-model="enableBrokerFirma"
                            />
                            <label
                              for="mandatCheckbox"
                              class="checkbox__label"
                            ></label>
                          </div>
                        </div>
                      </div> -->
                      <div class="form__field">
                        <VSelect
                          class="select select--default"
                          :class="{ 'select--error': companyHasError }"
                          :options="companies"
                          :reduce="(company) => company.id"
                          v-model="companyId"
                          label="name"
                          placeholder="Firma"
                        />
                        <div class="form__error" v-if="companyHasError">
                          Please select a company
                        </div>
                      </div>
<!--                      <div class="form__field">
                        <input
                          type="text"
                          class="input input&#45;&#45;default"
                          placeholder="Policy Nr."
                          v-model="policy.policyNumber"
                        />
                      </div>-->
                    </div>
                    <div class="policy__column">
                      <div class="form__field">
                        <VSelect
                          :options="agents"
                          :reduce="(agent) => agent.id"
                          class="select select--default"
                          :class="{ 'select--error': agentHasError }"
                          placeholder="Berater"
                          v-model="mortgage.agentId"
                          :getOptionLabel="
                            (agent) => `${agent.first_name} ${agent.last_name}`
                          "
                        />
                        <div class="form__error" v-if="agentHasError">
                          Please select an agent
                        </div>
                      </div>
                      <!-- <div class="form__field">
                        <VSelect
                          v-if="enableBrokerFirma"
                          class="select select--default"
                          :options="brokerCompanies"
                          :reduce="(company) => company.id"
                          label="name"
                          placeholder="Broker Firma"
                          v-model="policy.policyMandates.companyId"
                        />
                        <div
                          class="select select--default select--readonly"
                          v-else
                        >
                          Broker Firma
                        </div>
                      </div> -->
                      <div class="form__field">
                        <VSelect
                          :options="mortgageStatuses"
                          :reduce="(mortgage) => mortgage.id"
                          label="name"
                          class="select select--default"
                          :class="{ 'select--error': statusHasError }"
                          placeholder="Status"
                          v-model="mortgage.mortgageStatusId"
                        />
                        <div class="form__error" v-if="statusHasError">
                          Please select a policy status
                        </div>
                      </div>
                    </div>
                  </div>


                </div>

                <div v-if="showServices" class="policy__service-toggler">
                  <span>Services</span>
                  <div
                    class="policy__service-adder"
                    :class="{
                      'policy__service-adder--active': showServicesDropdown,
                    }"
                    @click="showServicesDropdown = !showServicesDropdown"
                  ></div>

                  <transition name="fade">
                    <div class="service-dropdown" v-if="showServicesDropdown">
                      <Loading v-if="servicesLoading" />
                      <div v-else class="service-dropdown__content">
                        <div class="service-dropdown__title">
                          {{ $t(`addPolicyModal.${"chooseServiceTitle"}`) }}
                        </div>

                        <div class="service-dropdown__body">
                          <div
                            v-if="!dropdownServices.length"
                            class="service-dropdown__no-data"
                          >
                            No services found
                          </div>

                          <template v-else>
                            <div
                              class="service-dropdown__item"
                              v-for="service in dropdownServices"
                              :key="service.id"
                            >
                              <div class="checkbox">
                                <input
                                  type="checkbox"
                                  class="checkbox__checkbox"
                                  :checked="
                                    services.some((s) => s.id === service.id)
                                  "
                                  :id="service.id"
                                  @input="addOrRemoveServices($event, service)"
                                />

                                <label
                                  :for="service.id"
                                  class="checkbox__label checkbox__label--smaller"
                                ></label>
                              </div>
                              <label
                                :for="service.id"
                                class="service-dropdown__item-title"
                              >
                                {{ service[`long_${language}`] }}
                              </label>
                            </div>
                          </template>
                        </div>

                        <div class="service-dropdown__buttons">
                          <button
                            type="button"
                            class="service-dropdown__button service-dropdown__button--submit"
                            @click="applyServices()"
                          ></button>
                          <button
                            type="button"
                            class="service-dropdown__button service-dropdown__button--cancel"
                            @click="closeServicesDropdown()"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>

                <div class="services" v-if="services && showServices">
                  <Service
                    v-for="service in services"
                    :key="service.id"
                    :service="service"
                    :companyId="companyId"
                    :deleteService="deleteService"
                    :servicesToDelete="servicesToDelete"
                  />
                </div>
              </div>
            </div>
          </transition>
        </form>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        type="button"
        class="modal__button modal__button--submit button button--default"
        @click="createMortgage()"
      >
        <span>{{ $t(`addMortgageModal.${"saveButton"}`) }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import statics from "@/statics";
import Loading from "@/partials/Loading";
import Service from "@/components/AddPolicyModal/Service";

import { parseCustomerName } from "@/helpers/entityParsers";
import moment from "moment";
export default {
  name: "AddPolicyModal",

  components: {
    Loading,
    Service,

  },

  data() {
    return {
      KVG: statics.KVG,
      parseCustomerName,
      currentPage: 0,
      showServicesDropdown: false,
      showServices: false,
      enableBrokerFirma: false,
      formLoading: false,
      isRequesting: false,
      servicesLoading: false,
      dropdownServices: [],
      companyId: 0,
      preServices: [],
      services: [],
      servicesToDelete: [],

      files: [],

      mortgage: {
        customerId: "",
        companyId: "",
        agentId: "",
        mortgageStatusId: "",
        policyNumber: "",
        policyMandates: {
          companyId: "",
        },
      },

      companyHasError: false,
      agentHasError: false,
      statusHasError: false,
    };
  },

  watch: {
    /*async companyId(id) {
      if (id) {
        this.companyHasError = false;
        this.showServices = true;
        this.servicesLoading = true;
        this.services = [];
        this.preServices = [];
        this.policy.companyId = id;

        if (this.formModels.length) {
          this.$store.commit("setFormModels", []);
        }

        if (this.singleModel) {
          this.$store.commit("setSingleModel", {
            ...this.singleModel,
            id: "",
            serviceId: "",
            startDate: "",
            franchise: "",
            accident: "",
            doctorName: "",
            price: "",
            interval: "",
            startDateHasError: false,
            priceHasError: false,
          });
        }

        if (this.singletonFormModels.length) {
          this.$store.commit("setSingletonFormModels", []);
        }

        try {
          this.dropdownServices = await this.$store.dispatch(
            "getCompanyServices",
            id
          );
        } catch (err) {
          console.error(err);
        }
        this.servicesLoading = false;
      } else {
        this.companyHasError = true;
        this.showServices = false;
      }
    },*/
    companyId(id) {
      if (id) {
        this.companyHasError = false;
        this.mortgage.companyId = id;
      }
    },
    "mortgage.agentId"(id) {
      if (id) {
        this.agentHasError = false;
      } else {
        this.agentHasError = true;
      }
    },

    "mortgage.mortgageStatusId"(id) {
      if (id) {
        this.statusHasError = false;
      } else {
        this.statusHasError = true;
      }
    },
  },

  computed: {
    ...mapGetters([
      "mortgageForms",
      "customerDetails",
      "formModels",
      "singleModel",
      "singletonFormModels",
      "servicesToRemove",
      "policyIsConverting",
      "convertingPolicyData",
      "customerMembers",
      "language",
    ]),

    agents() {
      return this.mortgageForms.agents;
    },

    companies() {
      return this.mortgageForms.companies;
    },



    mortgageStatuses() {
      return this.mortgageForms["mortgage-statuses"];
    },


  },

  destroyed() {
 //   this.$store.commit("setPolicyIsConverting", false);
  //  this.$store.commit("setConvertingPolicyData", {});
  },

  async created() {
    this.formLoading = true;

    await this.$store.dispatch("getMortgageForms");
    this.mortgage.customerId = this.customerDetails.id;
    this.formLoading = false;
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddMortgageModal", false);
    },

    goBack() {
      this.currentPage--;
      if (this.currentPage < 0) {
        this.closeModal();
        this.$store.commit("setShowAddCustomerServicesModal", true);
      }
    },

    addOrRemoveServices(event, service) {
      const { checked } = event.target;
      const existingService = this.preServices.findIndex(
        (s) => s.id === service.id
      );
      if (checked) {
        if (existingService === -1) {
          this.preServices = [...this.preServices, service];
          this.servicesToDelete.splice(existingService, 1);
          const serviceToRemoveExists = this.servicesToRemove.some(
            (s) => s.id === service.id
          );
          if (serviceToRemoveExists) {
            const filteredServices = this.servicesToRemove.filter(
              (s) => s.id !== service.id
            );
            this.$store.commit("setServicesToRemove", filteredServices);
          }
        }
      } else {
        this.servicesToDelete = [...this.servicesToDelete, service];
        this.preServices.splice(existingService, 1);
      }
    },

    applyServices() {
      this.services = [...this.preServices];

      if (this.servicesToRemove.length) {
        this.servicesToRemove.forEach((service) => {
          if (service.type === "KVG") {
            this.$store.commit("setSingleModel", {
              id: "",
              serviceId: "",
              startDate: "",
              franchise: "",
              accident: "",
              doctorName: "",
              price: "",
              interval: "",
              startDateHasError: false,
              priceHasError: false,
            });
          } else if (service.type === "multiple") {
            this.$store.commit("setFormModels", []);
          } else if (service.type === "singleton") {
            const modelToDelete = this.singletonFormModels.findIndex(
              (model) => model.serviceId === service.id
            );
            const singletonModels = [...this.singletonFormModels];
            singletonModels.splice(modelToDelete, 1);
            this.$store.commit("setSingletonFormModels", singletonModels);
          }
        });
        this.servicesToDelete = [];
        this.$store.commit("setServicesToRemove", []);
      }

      // This line of code always keeps the KVG service at the beginning of the array
      const kvg = this.services.find((service) => service.id === this.KVG);
      if (kvg) {
        const indexOfKvg = this.services.findIndex(
          (service) => service.id === this.KVG
        );
        this.services.splice(indexOfKvg, 1);
        this.services = [kvg, ...this.services];
        this.preServices = [...this.services];
      }

      this.showServicesDropdown = false;
    },

    closeServicesDropdown() {
      this.preServices = [...this.services];
      this.servicesToDelete = [];
      if (this.servicesToRemove.length) {
        this.$store.commit("setServicesToRemove", []);
      }
      this.showServicesDropdown = false;
    },

    deleteService(serviceId) {
      const serviceToDelete = this.services.findIndex(
        (service) => service.id === serviceId
      );
      this.services.splice(serviceToDelete, 1);
      this.preServices.splice(serviceToDelete, 1);
    },

    validatePolicyFields(callback) {
      const { agentId, companyId, mortgageStatusId } = this.mortgage;
      if (!companyId) {
        this.companyHasError = true;
      }
      if (!agentId) {
        this.agentHasError = true;
      }
      if (!mortgageStatusId) {
        this.statusHasError = true;
      }

      if (companyId && agentId && mortgageStatusId) {
        callback(true);
      } else {
        callback(false);
      }
    },

    validateModelFields(callback) {
      this.formModels.forEach((model, index) => {
        if (!model.startDate) {
          this.$store.commit("setSpecificFormModel", {
            index,
            key: "startDateHasError",
            data: true,
          });
        } else {
          this.$store.commit("setSpecificFormModel", {
            index,
            key: "startDateHasError",
            data: false,
          });
        }

        if (
          model.endDate &&
          moment(model.startDate).isAfter(moment(model.endDate))
        ) {
          this.$store.commit("setSpecificFormModel", {
            index,
            key: "startDateHasError",
            data: true,
          });
        }

        if (
          !model.price ||
          model.price < 1 ||
          statics.stringRegex.test(model.price) ||
          statics.symbolRegex.test(model.price)
        ) {
          this.$store.commit("setSpecificFormModel", {
            index,
            key: "priceHasError",
            data: true,
          });

        } else {
          this.$store.commit("setSpecificFormModel", {
            index,
            key: "priceHasError",
            data: false,
          });
        }
      });

      this.singletonFormModels.forEach((model, index) => {
        if (!model.startDate) {
          this.$store.commit("setSpecificSingletonFormModel", {
            index,
            key: "startDateHasError",
            data: true,
          });
        } else {
          this.$store.commit("setSpecificSingletonFormModel", {
            index,
            key: "startDateHasError",
            data: false,
          });
        }

        if (
          model.endDate &&
          moment(model.startDate).isAfter(moment(model.endDate))
        ) {
          this.$store.commit("setSpecificSingletonFormModel", {
            index,
            key: "startDateHasError",
            data: true,
          });
        }

        if (
            (!model.price ||
          model.price < 1 ||
          statics.stringRegex.test(model.price) ||
          statics.symbolRegex.test(model.price) ) && model.serviceId != 32
        ) {
          this.$store.commit("setSpecificSingletonFormModel", {
            index,
            key: "priceHasError",
            data: true,
          });

        } else {
          this.$store.commit("setSpecificSingletonFormModel", {
            index,
            key: "priceHasError",
            data: false,
          });
        }
      });

      const hasKvg =
        this.services.findIndex((service) => service.id === this.KVG) !== -1;

      if (hasKvg) {
        if (!this.singleModel.startDate) {
          this.$store.commit("setSpecificSingleModel", {
            key: "startDateHasError",
            data: true,
          });
        } else {
          this.$store.commit("setSpecificSingleModel", {
            key: "startDateHasError",
            data: false,
          });
        }

        if (
          moment(this.singleModel.startDate).isAfter(
            moment(this.singleModel.endDate)
          )
        ) {
          this.$store.commit("setSpecificSingleModel", {
            key: "startDateHasError",
            data: true,
          });
        }

        if (
          !this.singleModel.price ||
          this.singleModel.price < 1 ||
          statics.stringRegex.test(this.singleModel.price) ||
          statics.symbolRegex.test(this.singleModel.price)
        ) {
          this.$store.commit("setSpecificSingleModel", {
            key: "priceHasError",
            data: true,
          });
        } else {
          this.$store.commit("setSpecificSingleModel", {
            key: "priceHasError",
            data: false,
          });
        }
      }

      let models;
      if (hasKvg) {
        models = [
          ...this.formModels,
          ...this.singletonFormModels,
          { ...this.singleModel },
        ];
      } else {
        models = [...this.formModels, ...this.singletonFormModels];
      }

      let allFieldsAreValid = models.every(
        (model) => !model.startDateHasError && !model.priceHasError
      );
      if (allFieldsAreValid) {
        callback(true);
      } else {
        callback(false);
      }
    },
    openMortgageFilesModal(mortgage) {
      this.$store.commit("setMortgageId", mortgage.id);
      this.$store.commit("setMortgageDocuments", mortgage.documents);
      this.$store.commit("setShowMortgageFilesModal", true);
    },
    createMortgage() {
      this.isRequesting = true;
      this.validatePolicyFields(async (policyState) => {
        if(policyState)

        {
          const mortgage = { ...this.mortgage};

          try {
            const { data } = await this.$store.dispatch("addMortgage", mortgage);


            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Mortgage created successfully.",
            });

            await this.$store.dispatch(
                "getGlobalCustomer",
                this.customerDetails.id
            );

            this.closeModal();
            this.openMortgageFilesModal(data.data)
          } catch (err) {
            this.closeModal();
            console.error(err);
          }

        }else {
          this.isRequesting = false;
        }
      });
    }


  },
};
</script>

<style scoped lang="scss">


.policy {
  padding-bottom: 30px;
  box-shadow: none;
  &__form {
    display: flex;
    border-bottom: 1px solid var(--border-10);
    padding-bottom: 20px;
  }

  &__inputs {
    flex: 1;
    display: flex;
  }

  &__column {
    margin-right: 10px;
    flex: 1;

    .form__field {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .mandat {
    display: flex;
    align-items: center;
    margin: 31px 10px;

    &__title {
      color: var(--text-2);
      font-family: var(--muli-text-semi-bold);
      margin-right: 10px;
    }
  }

  ::v-deep &__service-toggler {
    position: relative;
    margin: 15px 0;
    padding-right: 20px;
    display: inline-flex;
    align-items: center;
    span {
      color: var(--text-2);
      font-size: var(--x-small);
      font-family: var(--muli-text-semi-bold);
    }
  }

  ::v-deep &__service-adder {
    width: 24px;
    height: 24px;
    background: var(--bg-2);
    margin-left: 22px;
    border-radius: var(--radius-3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background: url("../assets/icons/default/add_icon.svg") no-repeat center;
      background-size: 15px;
      transition: all 0.3s ease;
    }

    &--active:before,
    &:hover:before {
      transform: rotate(180deg);
    }
  }

  ::v-deep .service-dropdown {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 100%;
    min-height: 265px;
    background: var(--bg-1);
    padding: 10px 20px;
    border: 1px solid var(--border-2);
    border-radius: var(--radius-1);
    box-shadow: 0px 3px 30px var(--shadow-4);
    width: 400px;
    transition: all 0.3s ease;

    &__title {
      color: var(--text-5);
      margin-bottom: 15px;
    }

    &__item {
      display: flex;
      align-items: center;
      font-size: var(--x-small);
      color: var(--text-2);
      padding: 6px 0;
    }

    &__item-title {
      margin-left: 10px;
    }

    &__content {
      position: relative;
    }

    &__body {
      max-height: 176px;
      overflow: auto;
      margin-bottom: 10px;
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
    }

    &__button {
      width: 44px;
      height: 27px;
      border-radius: var(--radius-1);

      &--submit {
        background-color: var(--bg-2);
        margin-right: 20px;
        background-image: url("../assets/icons/default/tick_icon.svg");
      }

      &--cancel {
        background: var(--bg-12);
        background-image: url("../assets/icons/default/x_icon_white.svg");
      }

      &--submit,
      &--cancel {
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: center;
      }
    }
  }

  .services {
    border-top: 1px solid var(--border-10);
  }
}
</style>
