<template>
  <div
    class="file"
    :class="[
      { 'animate__animated animate__zoomOutLeft': isDeleting },
      { 'deleting': wasDeleted },
    ]"
  >
    <Loading v-if="isLoading" />

    <template v-else>
      <div
        class="file__data file__data--large file__data--editable file__data--name"
      >
        {{document.name}}
      </div>
      <div
          v-if="document.document_file"
        class="file__data file__data--medium icon icon__pdf"
        @click="openFile(document.document_file)"
      ></div>

      <div class="file__data file__data--medium"

      v-else
      >

        <div class="add__upload-wrapper">
          <button
              type="button"
              class="button button--default button--upload"
              @click="openSingleFileDialog()"
          >
            Upload File
          </button>
          <input

              type="file"
              class="add__upload "
              ref="inputFile"
              @input="selectSingleFile($event, document)"
          />
        </div>

      </div>

      <div class="file__data file__data--medium">
          <span v-if="document.document_file">{{createdAt(document.document_file.created_at)}}</span>
      </div>

      <!-- <div class="file__data file__data--small">
      <div class="checkbox">
        <input
          type="checkbox"
          :id="`privateCheckbox${file.id}`"
          class="checkbox__checkbox"
          v-model="isPrivate"
          @input="toggleFileProperty('private', $event.target.checked)"
        />
        <label
          :for="`privateCheckbox${file.id}`"
          class="checkbox__label"
        ></label>
      </div>
    </div> -->
      <div
        class="file__data file__data--small icon icon__delete"
        :class="{ 'icon__delete--active': showDeletePopup }"
        tabindex="-1"
        @focus="showDeletePopup = true"
        @focusout="showDeletePopup = false"
        ref="delete"
      >
        <transition name="fade">
          <div class="delete" v-if="showDeletePopup">
            <div class="delete__text">
              Are you sure you want to delete this File?
            </div>
            <div class="delete__buttons">
              <div
                class="button button--confirm"
                @click="deletePolicyFile(document.document_file)"
              ></div>
              <div
                class="button button--cancel"
                @click="closeDeletePopup()"
              ></div>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import moment from "moment";
import Loading from "@/partials/Loading";
export default {
  name: "Document",

  components: {

    Loading,
  },

  props: {
    document: {
      required: true,
      type: Object,
    },
    updateFiles: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      showEditablePopup: false,
      isRequired: false,
      isApp: false,
      isDeleting: false,
      wasDeleted: false,
      isLoading: false,
      showDeletePopup: false,
    };
  },

  computed: {
    ...mapGetters(["policyFilesModalIsTermination"]),

  },

  mounted() {
 //   this.isRequired = this.file.custom_properties.required;

  },

  methods: {
    createdAt(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    openSingleFileDialog() {
      const { inputFile } = this.$refs;
      inputFile.click();
    },

    async   selectSingleFile(event, document) {
      this.isLoading = true;
      await this.$store.dispatch("addMortgageFile", {
        documentId: document.id,
        file: event.target.files[0]
      });
      await this.updateFiles();
      this.isLoading = false;
      /*const { id } = this.$route.params;
      await this.$store.dispatch("getGlobalCustomer", id);
   */

/*      const { files } = event.target;
      const file = [...files][0];
      const { name } = file;
      const mimeType = this.parseFileExtension(name);
      this.files = [
        ...this.files,
        {
          name,
          type: mimeType,
          file,
        },
      ];
      this.$emit("input", this.files);*/
    },
    parseFileExtension(name) {
      const mimeTypeParts = name.split(".");
      let mimeType = mimeTypeParts[mimeTypeParts.length - 1].toLowerCase();
      if (mimeType === "jpg" || mimeType === "png" || mimeType === "svg") {
        mimeType = "image";
      }
      return mimeType;
    },

    async deletePolicyFile(file) {
      this.closeDeletePopup();
      this.isLoading = true;

      await this.$store.dispatch("deleteFile", file.uuid);

      this.isLoading = false;
      setTimeout(async () => {
        await this.updateFiles();
      }, 300);
    },


    async openFile(file) {
      await this.$store.dispatch("openFile", file);
    },

    openPolicyFilesModal() {
      this.$store.commit("setPolicyFileId", this.document.id);
      this.$store.commit("setPolicyFiles", this.document.document_files);
      this.$store.commit("setPolicyFilesModalIsTermination", false);
      this.$store.commit("setShowPolicyFilesModal", true);
    },

    closeDeletePopup() {
      this.$refs.delete.blur();
      this.showDeletePopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.file__data {
  position: relative;
  &--editable {
    span {
      position: relative;
      cursor: pointer;
    }
  }

  &--name {
    word-break: break-all;
  }
}
.button--upload{
  width: 120px;
  height: 30px;
  font-size: 12px;
}
.add__upload{
  opacity: 0;
  position: absolute;
}
.delete {
  position: absolute;
  z-index: 3;
  background: var(--bg-1);
  right: calc(100% + 5px);
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  display: flex;
  align-items: center;
  border-radius: var(--radius-1);
  border: 1px solid var(--border-2);
  padding: 10px 20px;
  box-shadow: 0px 3px 6px var(--shadow-7);

  &__text {
    line-height: 20px;
  }

  &__buttons {
    display: flex;
  }

  .button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: all 0.3s ease;

    &--confirm {
      background: url("../../assets/icons/default/confirm_icon.svg");
      margin: 0 15px;

      &:hover {
        background: url("../../assets/icons/default/confirm_hover_icon.svg");
      }
    }

    &--cancel {
      background: url("../../assets/icons/default/close_icon.svg");
      box-shadow: none;

      &:hover {
        background: url("../../assets/icons/default/close_hover_icon.svg");
      }
    }
  }
}
</style>
