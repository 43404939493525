<template>
  <div
    class="price"
    :class="[
      { 'animate__animated animate__zoomOutLeft': isDeleting },
      { 'price--deleting': wasDeleted },
    ]"
  >
    <div class="column column--branch">
      <span>{{ price.branch_category[`long_${language}`] }}</span>
    </div>
    <div class="column column--agent">
      <span @click="showPopupEditer = true"
        ><transition name="fade">
          <PopupEditer
            v-if="showPopupEditer"
            :selected="price.agent_provision * 100"
            @togglePopup="showPopupEditer = $event"
            @submit="changeAgentProvision($event)"
          />
        </transition>
        {{ agentProvision * 100 }}%</span
      >
    </div>
    <div class="column column--company">
      <span>{{ price.company_provision * 100 }}%</span>
    </div>
    <div class="icon icon__delete" @click="deletePrice()"></div>
  </div>
</template>

<script>
import PopupEditer from "@/partials/PopupEditer";
import { mapGetters } from "vuex";
export default {
  name: "PriceRow",

  components: {
    PopupEditer,
  },

  props: {
    price: {
      required: true,
      type: Object,
    },
    selectSale: {
      required: true,
      type: Function,
    },
    teamId: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      isDeleting: false,
      wasDeleted: false,
      showPopupEditer: false,
      agentProvision: "",
      agentProvisionCopy: "",
    };
  },

  computed: {
    ...mapGetters(["language"]),
  },

  mounted() {
    this.agentProvision = this.price.agent_provision;
    this.agentProvisionCopy = this.price.agent_provision;
  },

  methods: {
    async deletePrice() {
      this.isDeleting = true;
      await this.$store.dispatch("deleteSaleCategoryPrice", this.price.id);
      this.wasDeleted = true;
      setTimeout(async () => {
        await this.$store.dispatch("getSaleCategories");
        this.selectSale(this.price.sales_category_id);
      }, 300);
    },

    async changeAgentProvision(provision) {
      if (
        provision <= 100 &&
        provision > 0 &&
        provision !== this.agentProvisionCopy
      ) {
        this.agentProvision = provision / 100;
        await this.$store.dispatch("updateSaleCategoryPrice", {
          saleCategoryPriceId: this.price.id,
          provision,
        });
        if (this.teamId && this.$checkRolePermission("team_access")) {
          await this.$store.dispatch("getSaleCategoriesByTeam", this.teamId);
        } else {
          await this.$store.dispatch("getSaleCategories");
        }
        this.selectSale(this.price.sales_category_id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.price {
  border-bottom: 1px solid var(--border-15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  position: relative;
  max-height: 47px;
  transition: all 0.3s ease;

  &--deleting {
    padding: 0px 20px;
    max-height: 0px;
    overflow: hidden;
    border: 0;
  }

  .icon {
    position: absolute;
    right: 25px;
  }
}
</style>
