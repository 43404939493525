<template>
  <div
    class="policy-service"
    :class="[
      {
        'animate__animated animate__zoomOutLeft':
          serviceIsDeleting === service.id,
      },
      { deleting: isDeleted },
    ]"
  >
    <Loading v-if="serviceLoading" />
    <template v-else>
      <div class="policy-service__row">
        <div class="policy-service__data policy-service__data--type">
          {{ code }}
        </div>
        <div class="policy-service__data policy-service__data--underline">
          <span @click="showStartDatePopup = true">
            <transition name="fade" mode="in-out">
              <PopupEditer
                v-if="showStartDatePopup"
                :isDate="true"
                @togglePopup="showStartDatePopup = $event"
                :selected="unformattedStartDate"
                @submit="updatePolicyServiceStartDate($event)"
              />
            </transition>
            {{ startDate }}
          </span>
        </div>
        <div
          class="policy-service__data"
          :class="{ 'policy-service__data--underline': code !== 'KVG' }"
        >
          <span v-if="code !== 'KVG'" @click="showEndDatePopup = true">
            <transition name="fade" mode="in-out">
              <PopupEditer
                v-if="showEndDatePopup"
                :isDate="true"
                @togglePopup="showEndDatePopup = $event"
                :selected="unformattedEndDate"
                @submit="updatePolicyServiceEndDate($event)"
              />
            </transition>
            {{ endDate }}
          </span>
          <span v-else>{{ endDate }}</span>
        </div>
        <div class="policy-service__data">{{ dueDate }}</div>
        <div class="policy-service__data">{{ plateNumber }}</div>
        <div
          class="policy-service__data service__data--price"
          :class="{ 'policy-service__data--netto': isLastService }"
        >
          <div>{{ nettoPremium }}</div>
          <div class="policy-service__netto" v-if="isLastService">
            <span>Total:</span>{{ totalNetto.toFixed(2) }}
          </div>
        </div>
        <div
          class="policy-service__data policy-service__data--price"
          :class="{ 'policy-service__data--brutto': isLastService }"
        >
          <div>{{ bruttoPremium }}</div>
          <span class="policy-service__brutto" v-if="isLastService"
            >{{ totalBrutto }} .-CHF</span
          >
        </div>
        <div class="policy-service__data policy-service__data--underline">
          <span @click="showIntervalPopup = true">
            <transition name="fade" mode="in-out">
              <PopupEditer
                v-if="showIntervalPopup"
                @togglePopup="showIntervalPopup = $event"
                :items="intervals"
                :selected="premiumInterval"
                label="name"
                :position="'left'"
                @submit="updatePolicyServiceInterval($event)"
              />
            </transition>
            {{ premiumInterval }}
          </span>
        </div>
        <div class="policy-service__data policy-service__data--payment">
          <div
            class="icon icon__payment"
            :class="{ 'icon__payment--paid': isPaid }"
            @click="openPolicyProvisionModal()"
          ></div>
        </div>
        <div class="policy-service__data policy-service__data--delete">
          <div
            class="icon icon__delete"
            :class="{ 'icon__delete--active': showDeletePopup }"
            tabindex="-1"
            @focus="isPaid ? null : (showDeletePopup = true)"
            @focusout="isPaid ? null : (showDeletePopup = false)"
            ref="delete"
          >
            <transition name="fade">
              <div class="delete" v-if="showDeletePopup">
                <div class="delete__text">
                  Are you sure you want to delete this Service?
                </div>
                <div class="delete__buttons">
                  <div
                    class="button button--confirm"
                    @click="deleteService()"
                  ></div>
                  <div
                    class="button button--cancel"
                    @click="closeDeletePopup()"
                  ></div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="policy-service__name">{{ name }}</div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PopupEditer from "@/partials/PopupEditer";
import Loading from "@/partials/Loading";
import moment from "moment";
import statics from "@/statics";
export default {
  name: "PolicyService",

  components: {
    PopupEditer,
    Loading,
  },

  props: {
    service: {
      required: true,
      type: Object,
    },

    fetchGlobalCustomer: {
      required: true,
      type: Function,
    },

    isLastService: {
      required: true,
      type: Boolean,
    },

    totalNetto: {
      required: true,
      type: Number,
    },

    totalBrutto: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      showIntervalPopup: false,
      showStartDatePopup: false,
      showEndDatePopup: false,
      serviceLoading: false,
      serviceIsDeleting: "",
      isDeleted: false,
      showDeletePopup: false,
    };
  },

  computed: {
    ...mapGetters(["policyForms", "language"]),
    code() {
      return this.service.service
        ? this.service.service.code || statics.empty
        : null;
    },

    unformattedStartDate() {
      return this.service.start_date || statics.empty;
    },

    unformattedEndDate() {
      return this.service.end_date || statics.empty;
    },

    startDate() {
      return this.service.start_date
        ? moment(this.service.start_date.split("T")[0]).format("DD.MM.YYYY")
        : statics.empty;
    },

    endDate() {
      return this.service.end_date
        ? moment(this.service.end_date.split("T")[0]).format("DD.MM.YYYY")
        : statics.empty;
    },

    nettoPremium() {
      return this.service.netto_premium
        ? `${this.service.netto_premium} .-CHF`
        : statics.empty;
    },

    bruttoPremium() {
      return this.service.bruto_premium
        ? `${this.service.bruto_premium} .-CHF`
        : statics.empty;
    },

    premiumInterval() {
      return this.service.premium_interval
        ? this.service.premium_interval.name || statics.empty
        : statics.empty;
    },

    dueDate() {
      return statics.empty;
    },

    name() {
      return this.service.service
        ? this.service.service[`long_${this.language}`] || statics.empty
        : statics.empty;
    },

    plateNumber() {
      return this.service.plate_number || statics.empty;
    },

    intervals() {
      return this.policyForms.intervals;
    },

    isPaid() {
      return this.service.policy_service_payment
        ? this.service.policy_service_payment.paid
        : false;
    },
  },

  methods: {
    async deleteService() {
      const { id } = this.$route.params;
      this.serviceIsDeleting = this.service.id;
      this.closeDeletePopup();
      await this.$store.dispatch("deletePolicyService", this.service.id);
      this.isDeleted = true;
      setTimeout(async () => {
        await this.$store.dispatch("getGlobalCustomer", id);
      }, 300);
    },

    async updatePolicyServiceInterval(intervalId) {
      this.serviceLoading = true;
      await this.$store.dispatch("updatePolicyServiceInterval", {
        serviceId: this.service.id,
        intervalId,
      });
      await this.fetchGlobalCustomer();
      this.showIntervalPopup = false;
      this.serviceLoading = false;
    },

    async updatePolicyServiceStartDate(date) {
      this.serviceLoading = true;
      await this.$store.dispatch("updatePolicyServiceStartDate", {
        serviceId: this.service.id,
        date,
      });
      await this.fetchGlobalCustomer();
      this.showStartDatePopup = false;
      this.serviceLoading = false;
    },

    async updatePolicyServiceEndDate(date) {
      this.serviceLoading = true;
      await this.$store.dispatch("updatePolicyServiceEndDate", {
        serviceId: this.service.id,
        date,
      });
      await this.fetchGlobalCustomer();
      this.showEndDatePopup = false;
      this.serviceLoading = false;
    },

    openPolicyProvisionModal() {
      this.$store.commit("setSelectedPolicyService", this.service);
      this.$store.commit("setShowPolicyProvisionModal", true);
    },

    closeDeletePopup() {
      this.$refs.delete.blur();
      this.showDeletePopup = false;
    },
  },
};
</script>
