import api from "@/api";

export default {
  state: {
    searchCustomerForms: {},
    searchPotentialCustomerForms: {},
  },
  getters: {
    searchCustomerForms: (state) => state.searchCustomerForms,
    searchPotentialCustomerForms: (state) => state.searchPotentialCustomerForms,
  },
  mutations: {
    setSearchCustomerForms: (state, data) => {
      state.searchCustomerForms = data;
    },
    setSearchPotentialCustomerForms: (state, data) => {
      state.searchPotentialCustomerForms = data;
    },
  },
  actions: {
    searchPotentialCustomer: async (_, phone) =>
      new Promise((resolve, reject) => {
        api({ requiresAuth: true })
          .get(`/v1/filter/potential-customers?phone=${phone}`)
          .then(
            (response) => {
              const [data] = response.data.data;
              resolve(data);
            },
            (err) => {
              reject(err);
            }
          );
      }),

    searchUsers: async ({ commit }, searchKeywords) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          `/v1/search/users?search=${searchKeywords}`
        );
        const { data } = response;
        commit("setUsersPerPage", data);
      } catch (err) {
        console.error(err);
      }
    },

    getSearchCustomerForms: async ({ commit }) => {
      try {
        const {
          data: { data },
        } = await api({ requiresAuth: true }).get(
          "/v1/partials/search-customers"
        );
        commit("setSearchCustomerForms", data);
      } catch (err) {
        console.error(err);
      }
    },

    getSearchPotentialCustomerForms: async ({ commit }) => {
      try {
        const {
          data: { data },
        } = await api({ requiresAuth: true }).get(
          "/v1/partials/search-potential-customers"
        );
        commit("setSearchPotentialCustomerForms", data);
      } catch (err) {
        console.error(err);
      }
    },

    searchPotentialCustomers: async ({ commit }, { page, ...searchData }) => {
      try {
        let data = null;
        if (
          searchData.search ||
          searchData.agents ||
          searchData.companyTypes.length ||
          searchData.branchCategories.length ||
          searchData.priorities.length ||
          searchData.birthday ||
          searchData.startDate ||
          searchData.endDate ||
          searchData.zip
        ) {
          data = await api({ requiresAuth: true }).post(
            page
              ? `/v1/search/potential-customers?page=${page}`
              : "/v1/search/potential-customers",
            searchData
          );
        } else {
          data = await api({ requiresAuth: true }).get(
            `/v1/potential-customers?page=${page}`
          );
        }

        commit("setCustomersPerPage", data.data);
      } catch (err) {
        console.error(err);
      }
    },

    searchCustomers: async ({ commit }, { page, ...searchData }) => {
      try {
        let data = null;
        if (
          searchData.search ||
          searchData.agents.length ||
          searchData.companyTypes.length ||
          searchData.companies.length ||
          searchData.branchCategories.length ||
          searchData.policyStatuses.length ||
          searchData.birthday ||
          searchData.startDate ||
          searchData.endDate ||
          searchData.zip
        ) {
          data = await api({ requiresAuth: true }).post(
            page ? `/v1/search/customers?page=${page}` : "/v1/search/customers",
            searchData
          );
        } else {
          data = await api({ requiresAuth: true }).get(
            `/v1/customers?page=${page}`
          );
        }

        commit("setCustomersPerPage", data.data);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
