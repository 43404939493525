import api from "@/api";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    changePolicyServicePaymentPrice: async (
      _,
      { policyServiceId, total, state }
    ) => {
      if (state === "create") {
        return await api({ requiresAuth: true }).post(
          `/v1/policy-services/${policyServiceId}/policy-service-payments`,
          {
            total,
          }
        );
      } else if (state === "update") {
        return await api({
          requiresAuth: true,
        }).patch(`/v1/policy-service-payments/${policyServiceId}`, { total });
      }
    },

    changePolicyServicePaidState: async (_, { policyServiceId, paid }) =>
      await api({ requiresAuth: true }).patch(
        `/v1/policy-service-payments/${[policyServiceId]}/paid`,
        {
          paid,
        }
      ),
  },
};
