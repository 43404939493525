var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu",class:{ 'menu--collapse': !_vm.showLeftMenu }},[_c('div',{staticClass:"menu__links"},[_c('div',{staticClass:"menu__link menu__link--menu",class:{ 'menu__link--collapse': !_vm.showLeftMenu },on:{"click":function($event){_vm.leftMenuLoading ? null : _vm.toggleLeftMenu(!_vm.showLeftMenu)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("leftMenu.menu")))])]),_c('div',{staticClass:"menu__link menu__link--add",on:{"click":function($event){return _vm.openCustomerModal()}}},[_vm._v(" "+_vm._s(_vm.$t("leftMenu.new"))+" ")]),(_vm.$checkRolePermission('customer_access'))?_c('router-link',{staticClass:"menu__link menu__link--customers",class:{
          'router-link-active router-link-exact-active':
            _vm.$route.params.entity === 'customer',
        },attrs:{"to":{
          name: 'Customers',
        }}},[_vm._v(_vm._s(_vm.$t(("leftMenu." + ("customers")))))]):_vm._e(),(_vm.$checkRolePermission('user_access'))?_c('router-link',{staticClass:"menu__link menu__link--users",class:{
          'router-link-active router-link-exact-active':
            _vm.$route.params.entity === 'user',
        },attrs:{"to":{
          name: 'Users',
          query: {
            page: 1,
          },
        }}},[_vm._v(_vm._s(_vm.$t(("leftMenu." + ("users")))))]):_vm._e(),(_vm.$checkRolePermission('company_access'))?_c('router-link',{staticClass:"menu__link menu__link--contact",attrs:{"to":{ name: 'Contacts' }}},[_vm._v(_vm._s(_vm.$t(("leftMenu." + ("contacts")))))]):_vm._e()],1),_c('div',{staticClass:"menu__links menu__links--bottom"},[(_vm.$checkRolePermission('settings_access'))?_c('router-link',{staticClass:"menu__link menu__link--settings",class:{
          'router-link-active link--settings router-link-exact-active':
            _vm.$route.name === 'PriceCategories' || _vm.$route.name === 'UserRights',
        },attrs:{"to":{ name: 'Settings' }}},[_vm._v(_vm._s(_vm.$t("leftMenu.settings")))]):_vm._e(),_vm._m(0),_c('a',{staticClass:"support button button--default button--shadow",class:{ 'support--collapse': !_vm.showLeftMenu },attrs:{"href":"https://forms.monday.com/forms/embed/06648888d44819daeab87a169492d20f?r=use1","target":"_blank"}},[_vm._m(1),_c('span',{staticClass:"support__title"},[_vm._v("Support")])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu__logo"},[_c('img',{attrs:{"src":require("../assets/icons/default/logo_main_left_menu.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"support__icon"},[_c('img',{attrs:{"src":require("../assets/icons/default/Bold 2px-headphone.svg"),"alt":""}})])}]

export { render, staticRenderFns }