<template>
  <div class="categories">
    <div class="page__header">
      <div class="page__title">sales category</div>
      <button
        class="button button--default"
        @click="$store.commit('setShowAddSaleCategoryModal', true)"
      >
        Add New
      </button>
    </div>

    <div class="categories__container">
      <div class="sales box box--default">
        <div class="label label--gradient">Choose Sales Category</div>

        <Loading v-if="salesLoading" />

        <div v-else class="sales__body">
          <div class="sales__teams" v-if="$checkRolePermission('team_access')">
            <VSelect
              class="select select--default"
              :options="teams"
              :reduce="(team) => team.id"
              @input="$store.commit('setSaleCategoryTeamId', $event)"
              :value="saleCategoryTeamId"
              label="name"
              placeholder="Select Team"
            />
          </div>
          <div
            @click="selectSale(sale.id)"
            class="sales__sale"
            :class="{ 'sales__sale--selected': sale.id === selectedSale.id }"
            v-for="sale in saleCategories"
            :key="sale.id"
          >
            {{ sale.name }}
          </div>
        </div>
      </div>
      <div class="prices box box--default">
        <div class="label label--gradient">{{ selectedSale.name }}</div>

        <div class="prices__body">
          <div class="page__header">
            <div class="page__title">{{ selectedSale.name_en }}</div>
          </div>

          <div class="prices__header">
            <div class="column column--branch">
              Branch
            </div>
            <div class="column column--agent">
              Agent
            </div>
            <div class="column column--company">
              Company
            </div>
          </div>

          <div class="prices__table">
            <PriceRow
              v-for="price in selectedSale.sales_category_prices"
              :key="price.id"
              :price="price"
              :selectSale="selectSale"
              :teamId="saleCategoryTeamId"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/partials/Loading";
import PriceRow from "@/components/PriceCategories/PriceRow";
export default {
  name: "PriceCategories",

  components: {
    Loading,
    PriceRow,
  },

  data() {
    return {
      salesLoading: false,
      selectedSale: {},
    };
  },

  watch: {
    async saleCategoryTeamId(teamId) {
      this.salesLoading = true;
      if (teamId) {
        await this.$store.dispatch("getSaleCategoriesByTeam", teamId);
      } else {
        await this.$store.dispatch("getSaleCategories");
      }
      this.setFirstSaleCategory();
      this.salesLoading = false;
    },
  },

  computed: {
    ...mapGetters([
      "saleCategories",
      "saleCategoriesData",
      "saleCategoryTeamId",
    ]),

    teams() {
      return this.saleCategoriesData.teams;
    },
  },

  async mounted() {
    this.salesLoading = true;
    await this.$store.dispatch("getSaleCategories");
    await this.$store.dispatch("getSaleCategoriesFormData");
    this.setFirstSaleCategory();
    this.salesLoading = false;
  },

  methods: {
    setFirstSaleCategory() {
      if (this.saleCategories.length) {
        const [firstSale] = this.saleCategories;
        this.selectedSale = firstSale;
      } else {
        this.selectedSale = {};
      }
    },

    selectSale(saleId) {
      this.selectedSale = this.saleCategories.find(
        (sale) => sale.id === saleId
      );
    },
  },
};
</script>

<style scoped lang="scss">
.categories {
  height: 100%;

  .page__header {
    .button {
      width: 180px;
    }
  }

  &__container {
    height: calc(100% - 48px - 20px);
    display: flex;
  }

  .sales,
  .prices {
    height: 100%;
    background: var(--bg-1);
    position: relative;
  }

  .sales__sale,
  .prices__price {
    color: var(--text-9);
    font-size: var(--medium);
    padding: 10px 20px;
    cursor: pointer;

    &:hover,
    &--selected {
      background: var(--bg-17);
    }
  }

  .sales,
  .prices {
    &__body {
      margin-top: 65px;
      padding-top: 20px;
      overflow: auto;
    }
  }

  .sales {
    width: 400px;
    margin-right: 20px;

    &__teams {
      padding: 0 20px;
      margin-bottom: 22px;
    }

    &__body {
      height: calc(100% - 65px);
    }
  }

  .prices {
    flex: 1;

    ::v-deep.column {
      &--branch {
        flex: 1;
      }

      &--agent {
        flex: 4;
        text-align: right;
        padding-right: 90px;
      }

      &--company {
        flex: 2;
        padding-left: 100px;
      }

      &--branch,
      &--agent {
        border-right: 1px solid var(--border-6);
      }
    }

    &__body {
      padding-top: 0;
      height: calc(100% - 65px);
      overflow: initial;

      &--stretch {
        height: calc(100% - 65px);
      }
    }

    &__header {
      box-shadow: 0px 2px 4px var(--shadow-1);
      border-radius: var(--radius-1);
      display: flex;
      padding: 10px 20px;
      color: var(--text-2);
    }

    .page__header {
      padding-left: 20px;
    }

    &__table {
      height: calc(100% - 24px - 20px - 36px);
      overflow: auto;

      ::v-deep .column {
        border-right: none;
        position: relative;

        &--branch {
          span {
            position: absolute;
            top: -8px;
            white-space: nowrap;
          }
        }

        &--agent,
        &--company {
          span {
            position: relative;
          }
        }

        &--agent {
          span {
            left: -2px;
            text-decoration: underline;
            color: var(--text-10);
            cursor: pointer;
          }
        }

        &--company {
          span {
            left: 20px;
          }
        }
      }
    }
  }
}
</style>
