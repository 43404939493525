<template>
  <div class="customer row row--hover box box--default">
    <div
      class="row__data row__data--small row__data--image"
      @click="goToCustomerFullView()"
    >
      <img v-if="customerThumbnail" :src="customerThumbnail" alt="" />
      <div v-else class="thumbnail thumbnail--customer" :style="color">
        {{ parseInitials(customer.first_name, customer.last_name) }}
      </div>
    </div>
    <div
      class="row__data row__data--name row__data--large"
      @click="goToCustomerFullView()"
    >
      {{ customerName }}
    </div>
    <div class="row__data row__data--contact row__data--medium">
      <a
        class="row__phone icon__phone"
        :href="customerPhone ? `tel:${customerPhone}` : 'javascript:void(0)'"
        :class="{ 'icon__phone--active': customerPhone }"
      ></a>
      <a
        class="row__mail icon__mail"
        :href="customerEmail ? `mailto:${customerEmail}` : 'javascript:void(0)'"
        :class="{ 'icon__mail--active': customerEmail }"
      ></a>
    </div>
    <div
      class="row__data row__data--address row__data--large"
      @click="goToCustomerFullView()"
    >
      {{ `${customerStreet} ${customerAddressNumber}` }}
    </div>
    <div
      class="row__data row__data--zip row__data--small"
      @click="goToCustomerFullView()"
    >
      {{ customerZip }}
    </div>
    <div
      class="row__data row__data--city row__data--medium"
      @click="goToCustomerFullView()"
    >
      {{ customerCity }}
    </div>
    <div
      class="row__data row__data--date row__data--medium"
      @click="goToCustomerFullView()"
    >
      {{ createdAt }}
    </div>
    <div
      class="row__data row__data--date row__data--medium"
      @click="goToCustomerFullView()"
    >
      {{ updatedAt }}
    </div>
    <div class="row__data row__data--type row__data--large">
      <VSelect
        v-if="insurances"
        :options="insurances"
        label="title"
        class="select select--default customer__dropdown"
        v-model="selectedInsurance"
      />
      <span v-else>-</span>
    </div>
    <div class="row__data row__data--company row__data--large">
      <VSelect
        v-if="companies"
        :options="companies"
        label="name"
        class="select select--default customer__dropdown"
        v-model="selectedCompany"
      />
      <span v-else>-</span>
    </div>
    <div
      class="row__data row__data--action row__data--small"
      @click.self="showActionsDropdown = !showActionsDropdown"
    >
      <RowActionDropdown
        v-if="showActionsDropdown"
        @toggleDropdown="showActionsDropdown = $event"
        :onEdit="editCustomer"
        :onDelete="deleteCustomer"
      />
    </div>
  </div>
</template>

<script>
import { parseCustomerName, parseInitials } from "@/helpers/entityParsers";
import RowActionDropdown from "@/components/RowActionDropdown";
import statics from "@/statics";
import { formatDisplayDate } from "@/helpers/dateFormatters";
import { mapGetters } from "vuex";
export default {
  name: "Customer",

  components: {
    RowActionDropdown,
  },

  props: {
    customer: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      showActionsDropdown: false,
      parseInitials,
      formatDisplayDate,
      selectedInsurance: {},
      selectedCompany: {},
    };
  },

  computed: {
    ...mapGetters(["language"]),

    customerName() {
      return parseCustomerName(this.customer);
    },
    customerStreet() {
      return this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].street
        : statics.empty;
    },
    customerAddressNumber() {
      return this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].number || ""
        : statics.empty;
    },
    customerZip() {
      return this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].zip
        : statics.empty;
    },
    customerCity() {
      return this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].city
        : statics.empty;
    },
    updatedAt() {
      return this.formatDisplayDate(this.customer.updated_at) || statics.empty;
    },

    createdAt() {
      return this.formatDisplayDate(this.customer.created_at) || statics.empty;
    },

    customerEmail() {
      return this.customer.customer_emails.length
        ? this.customer.customer_emails[0].email
        : null;
    },

    customerPhone() {
      return this.customer.customer_phones.length
        ? this.customer.customer_phones[0].phone
        : null;
    },

    customerThumbnail() {
      return this.customer.photo ? this.customer.photo.thumbnail : null;
    },

    color() {
      return this.customer.color
        ? { background: this.customer.color }
        : { background: "#7f8fa4" };
    },

    insurances() {
      if (this.customer.policies.length) {
        const insurances = this.customer.policies
          .map((policy) => {
            if (policy.policy_services.length) {
              return policy.policy_services;
            }
          })
          .flat();
        if (insurances.length) {
          return insurances.map((service, index) => {
            if (service) {
              return {
                id: index,
                title: service.service.branch_category
                  ? service.service.branch_category[`long_${this.language}`]
                  : "",
              };
            }
          });
        }
      }
      return null;
    },

    companies() {
      if (this.customer.policies.length) {
        return this.customer.policies.map((policy, index) => ({
          id: index,
          name: policy.company.name,
        }));
      }
      return null;
    },
  },

  created() {
    if (this.insurances) {
      const { id, title } = this.insurances[0];
      this.selectedInsurance = {
        id,
        title,
      };
    }
    if (this.companies) {
      const { id, name } = this.companies[0];
      this.selectedCompany = {
        id,
        name,
      };
    }
  },

  methods: {
    goToCustomerFullView() {
      this.$router.push({
        name: "AccountDetails",
        params: {
          entity: "customer",
          id: this.customer.id,
        },
      });
    },

    editCustomer() {
      const { id: memberType } = this.customer.member_type;
      if (memberType === 1) {
        this.$store.commit("setCompanyIsEdit", true);
        this.$store.commit("setShowAddCompanyModal", true);
        this.$store.commit("setCompanyToEdit", this.customer.id);
      } else if (memberType === 2) {
        this.$store.commit("setPrivateIsEdit", true);
        this.$store.commit("setShowAddPrivateModal", true);
        this.$store.commit("setPrivateToEdit", this.customer.id);
      }
    },

    deleteCustomer() {
      this.$store.commit("setDeleteModal", {
        display: true,
        message: `'${
          this.customer.member_type.id === 1
            ? this.customer.company_name
            : this.customer.first_name
        }'`,
        action: this.$store._actions.deleteCustomer,
        id: this.customer.id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.customer__dropdown {
  width: 100%;
  background: 0;
}
</style>
