import api from "@/api";

export default {
  state: {
    mortgageDocuments: [],
    mortgageId: "",
  },
  getters: {
    mortgageDocuments: (state) => state.mortgageDocuments,
    mortgageId: (state) => state.mortgageId,
  },
  mutations: {
   setMortgageDocuments: (state, data) => {
      state.mortgageDocuments = data;
    },

    setMortgageId: (state, data) => {
      state.mortgageId = data;
    },
  },
  actions: {
    addMortgage: (
        _,
        {
          customerId,
          companyId,
          agentId,
          mortgageStatusId,

        }
    ) =>
        api({ requiresAuth: true }).post("/v1/mortgages", {
          customer_id: customerId,
          company_id: companyId,
          agent_id: agentId,
          service_id: 7, //Static for demo purposes
          //policy_number: policyNumber,
          mortgage_status_id: mortgageStatusId,
         // policy_mandates: policyMandates,
         // policy_services: policyServices,
        }),
    updateMortgageStatus: async (_, { mortgageId, mortgageStatusId }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/mortgages/${mortgageId}/statuses`,
          {
            mortgage_status_id: mortgageStatusId,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    updateMortgageAgent: async (_, { mortgageId, agentId }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/mortgages/${mortgageId}/agents`,
          {
            agent_id: agentId,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },


    addMortgageFile: async (_, { documentId, files }) => {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append("document_files[]", file);
        });
      try {
        await api({ requiresAuth: true }).post(
          `/v1/mortgage-documents/${documentId}/files`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
};
