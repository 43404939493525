var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details"},[_vm._m(0),_c('div',{staticClass:"details__content"},[_c('div',{staticClass:"detail box box--default"},[(_vm.detailsLoading)?_c('Loading'):[_c('div',{staticClass:"detail__edit icon icon__edit",on:{"click":function($event){_vm.$route.params.entity === 'customer' ||
              _vm.$route.params.entity === 'potential-customer'
                ? _vm.editCustomer()
                : _vm.editUser()}}}),_c('div',{staticClass:"detail__info"},[_c('div',{staticClass:"detail__avatar"},[(_vm.photo)?_c('img',{attrs:{"src":_vm.photo,"alt":""}}):(_vm.memberType === _vm.statics.memberTypes.company)?_c('div',{staticClass:"detail__avatar-company-icon"},[_c('img',{attrs:{"src":require("../assets/icons/default/Bold 2px-building.svg"),"alt":""}})]):_c('div',{staticClass:"initials",style:({ background: _vm.customer.color || '#7f8fa4' })},[_vm._v(" "+_vm._s(_vm.parseInitials(_vm.customer.first_name, _vm.customer.last_name))+" ")])]),_c('div',{staticClass:"detail__splitter"}),_c('div',{staticClass:"detail__profile"},[(
                  _vm.$route.params.entity === 'customer' ||
                    _vm.$route.params.entity === 'potential-customer'
                )?_c('div',{staticClass:"detail__name"},[(_vm.memberType === _vm.statics.memberTypes.company)?_c('span',[_vm._v(_vm._s(_vm.customer.company_name))]):(_vm.memberType === _vm.statics.memberTypes.private)?_c('span',[_vm._v(_vm._s(((_vm.customer.first_name) + " " + (_vm.customer.last_name))))]):_vm._e()]):_c('div',{staticClass:"detail__name"},[_vm._v(" "+_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name)+" ")]),(
                  _vm.$route.params.entity === 'customer' ||
                    _vm.$route.params.entity === 'potential-customer'
                )?[(_vm.email.length)?_c('div',{staticClass:"detail__emails"},[_c('div',{staticClass:"detail__email"},[_vm._v(" "+_vm._s(_vm.email[0].email)+" ")]),_vm._l((_vm.email),function(email,index){return _c('div',{key:("email" + (email.id)),staticClass:"detail__email detail__email--secondary",style:(_vm.showDetails
                        ? { maxHeight: '16px' }
                        : { maxHeight: '0', margin: '0' })},[(index !== 0)?_c('span',[_vm._v(_vm._s(email.email))]):_vm._e()])})],2):_vm._e(),(_vm.phone.length)?_c('div',{staticClass:"details__phones"},[_c('div',{staticClass:"detail__phone"},[_vm._v(" "+_vm._s(_vm.phone[0].phone)+" ")]),_vm._l((_vm.phone),function(phone,index){return _c('div',{key:("phone" + (phone.id)),staticClass:"detail__phone detail__phone--secondary",style:(_vm.showDetails
                        ? { maxHeight: '16px' }
                        : { maxHeight: '0', margin: '0' })},[(index !== 0)?_c('span',[_vm._v(_vm._s(phone.phone))]):_vm._e()])})],2):_vm._e()]:[_c('div',{staticClass:"detail__email"},[_vm._v(" "+_vm._s(_vm.email)+" ")]),_c('div',{staticClass:"detail__phone"},[_vm._v(" "+_vm._s(_vm.phone)+" ")])]],2),_c('div',{staticClass:"detail__splitter"}),_c('div',{staticClass:"det",on:{"click":function($event){_vm.showDetails = !_vm.showDetails}}},[_c('div',{staticClass:"det__header"},[_c('div',{staticClass:"det__title"},[(_vm.showDetails)?_c('span',[_vm._v("Less details...")]):_c('span',[_vm._v("More details...")])]),_c('div',{staticClass:"det__chevron",class:{ 'det__chevron--more': _vm.showDetails }})])]),_c('div',{staticClass:"detail__splitter"}),_c('div',{staticClass:"detail__about"},[(_vm.memberType === _vm.statics.memberTypes.private)?[_c('div',{staticClass:"detail__label"},[_vm._v("Birthday:")]),_c('div',{staticClass:"detail__detail"},[_vm._v(_vm._s(_vm.birthday))])]:_vm._e(),_c('div',{staticClass:"detail__label"},[_vm._v("Address:")]),_c('div',{staticClass:"detail__detail"},[_c('span',[_vm._v(_vm._s(_vm.street)+" ")]),_c('span',[_vm._v(_vm._s(_vm.number))])]),_c('div',{staticClass:"detail__label"},[_vm._v("Zip / City:")]),_c('div',{staticClass:"detail__detail"},[_c('span',[_vm._v(_vm._s(_vm.zip))]),_c('span',[_vm._v(" / ")]),_c('span',[_vm._v(_vm._s(_vm.city))])]),(_vm.memberType === _vm.statics.memberTypes.private)?[_c('div',{staticClass:"detail__label"},[_vm._v("Nationality:")]),_c('div',{staticClass:"detail__detail"},[_vm._v(" "+_vm._s(_vm.country)+" ")])]:_vm._e()],2),_c('div',{ref:"details",staticClass:"detail__about",style:(_vm.showDetails
                  ? { maxHeight: ((_vm.detailsHeight()) + "px") }
                  : { maxHeight: '0px' })},[_c('div',{staticClass:"detail__label"},[_vm._v("Language:")]),_c('div',{staticClass:"detail__detail"},[_vm._v(_vm._s(_vm.language))]),_c('div',{staticClass:"detail__label"},[_vm._v("Job:")]),_c('div',{staticClass:"detail__detail"},[_vm._v(_vm._s(_vm.job))]),_c('div',{staticClass:"detail__label"},[_vm._v("Employer:")]),_c('div',{staticClass:"detail__detail"},[_vm._v(_vm._s(_vm.employer))]),_c('div',{staticClass:"detail__label"},[_vm._v("AHV Nr.:")]),_c('div',{staticClass:"detail__detail"},[_vm._v(" "+_vm._s(_vm.ahvNr)+" ")]),_c('div',{staticClass:"detail__label"},[_vm._v("IBAN:")]),_c('div',{staticClass:"detail__detail"},[_vm._v(" "+_vm._s(_vm.iBan)+" ")]),_c('div',{staticClass:"detail__label"},[_vm._v("Bankname:")]),_c('div',{staticClass:"detail__detail"},[_vm._v(" "+_vm._s(_vm.bankName)+" ")])])]),(
              _vm.$route.params.entity === 'customer' ||
                _vm.$route.params.entity === 'potential-customer'
            )?_c('div',{staticClass:"detail__members"},[_vm._l((_vm.customerMembers),function(member){return _c('div',{key:member.id,staticClass:"member",class:[
                ("member--" + (_vm.parseMemberGender(member.gender_id))),
                { 'member--active': _vm.customer.id === member.id } ],on:{"click":function($event){return _vm.selectMember(member.id)}}},[_c('div',{staticClass:"member__overlay"}),_c('div',{staticClass:"member__icon"}),_c('div',{staticClass:"member__name"},[_vm._v(" "+_vm._s(_vm.parseCustomerName(member))+" ")]),(member.parent_id)?_c('div',{staticClass:"icon icon__delete",on:{"click":function($event){$event.stopPropagation();return _vm.deleteMember(member.id)}}}):_vm._e()])}),_c('button',{staticClass:"button button--default",on:{"click":function($event){return _vm.openAddMemberModal()}}},[_c('div',{staticClass:"button__icon"}),_c('div',{staticClass:"button__text"},[_vm._v(" "+_vm._s(_vm.$t(("accountDetails." + ("details") + "." + ("addMemberButton"))))+" ")])])],2):_vm._e(),(_vm.$route.params.entity === 'user')?_c('button',{staticClass:"button button--default detail__password-button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$store.commit('setShowResetPasswordModal', true)}}},[_vm._v(" Reset Password ")]):_vm._e()]],2),_c('div',{staticClass:"container"},[_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tab tab--mortgages",class:{ 'tab--active': _vm.activeTab === 4 },on:{"click":function($event){_vm.activeTab = 4}}},[(
                _vm.$route.params.entity === 'customer' ||
                  _vm.$route.params.entity === 'potential-customer'
              )?_c('span',[_vm._v(_vm._s(_vm.$t(("accountDetails." + ("tabs") + "." + ("mortgage-document")))))]):_c('span',[_vm._v("Activity Log")])])]),_c('div',{staticClass:"content"},[(
              _vm.$route.params.entity === 'customer' ||
                _vm.$route.params.entity === 'potential-customer'
            )?[_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[(_vm.activeTab === 0)?_c('PolicyTable',{class:{ content__top: _vm.activeTab === 0 },attrs:{"policies":_vm.policies,"terminations":_vm.terminations,"detailsLoading":_vm.detailsLoading}}):_vm._e(),(_vm.activeTab === 1)?_c('PotentialPolicyTable',{class:{ content__top: _vm.activeTab === 1 },attrs:{"potentialPolicies":_vm.potentialPolicies,"detailsLoading":_vm.detailsLoading}}):_vm._e(),(_vm.activeTab === 2)?_c('Invoices',{class:{ content__top: _vm.activeTab === 2 },attrs:{"detailsLoading":_vm.detailsLoading,"policies":_vm.policies}}):_vm._e(),(_vm.activeTab === 3)?_c('Accidents',{class:{ content__top: _vm.activeTab === 3 },attrs:{"detailsLoading":_vm.detailsLoading,"policies":_vm.policies}}):_vm._e(),(_vm.activeTab === 4)?_c('MortgageDocumentTable',{class:{ content__top: _vm.activeTab === 1 },attrs:{"mortgage-documents":_vm.mortgageDocuments,"detailsLoading":_vm.detailsLoading}}):_vm._e()],1)]:[_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[(_vm.activeTab === 0)?_c('div',{staticClass:"notifications"},_vm._l((_vm.notifications),function(notification){return _c('Notification',{key:notification.id,attrs:{"notification":notification}})}),1):_vm._e()])]],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page__header"},[_c('div',{staticClass:"page__title"},[_vm._v("ACCOUNT DETAILS")]),_c('div',{staticClass:"page__right"})])}]

export { render, staticRenderFns }