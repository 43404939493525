<template>
  <div class="customers">
    <div class="page__header">
      <div class="page__title">customers</div>
      <div class="page__right">
        <div
          class="selected-filters"
          v-if="Object.keys(searchCustomerForms).length"
        >
          <div
            class="selected-filters__filter"
            v-for="agentId in selectedAgents"
            :key="`agent${agentId}`"
          >
            <span>{{
              `${
                searchCustomerForms.agents.find((item) => item.id === agentId)
                  .first_name
              } ${
                searchCustomerForms.agents.find((item) => item.id === agentId)
                  .last_name
              }`
            }}</span>
            <div
              class="selected-filters__remove"
              @click="removeAgent(agentId)"
            ></div>
          </div>
          <div
            class="selected-filters__filter"
            v-for="typeId in selectedCompanyTypes"
            :key="`type${typeId}`"
          >
            <span>{{
              searchCustomerForms.companyTypes.find(
                (item) => item.id === typeId
              ).type
            }}</span>
            <div
              class="selected-filters__remove"
              @click="removeCompanyType(typeId)"
            ></div>
          </div>
          <div
            class="selected-filters__filter"
            v-for="companyId in selectedCompanies"
            :key="`company${companyId}`"
          >
            {{
              searchCustomerForms.companies.find(
                (item) => item.id === companyId
              ).name
            }}
            <div
              class="selected-filters__remove"
              @click="removeCompany(companyId)"
            ></div>
          </div>
          <div
            class="selected-filters__filter"
            v-for="categoryId in selectedBranchCategories"
            :key="`category${categoryId}`"
          >
            <span>{{
              searchCustomerForms.branchCategories.find(
                (item) => item.id === categoryId
              )[`long_${language}`]
            }}</span>
            <div
              class="selected-filters__remove"
              @click="removeBranchCategory(categoryId)"
            ></div>
          </div>
          <div
            class="selected-filters__filter"
            v-for="statusId in selectedPolicyStatuses"
            :key="`status${statusId}`"
          >
            <span>{{
              searchCustomerForms.policyStatuses.find(
                (item) => item.id === statusId
              )[`long_${language}`]
            }}</span>
            <div
              class="selected-filters__remove"
              @click="removePolicyStatus(statusId)"
            ></div>
          </div>
        </div>
        <form class="search search--filter" @submit.prevent="keywordsSearch()">
          <div class="search__wrapper">
            <input
              class="search__search"
              ref="search"
              :class="{ 'search__search--active': searchKeywords }"
              type="text"
              v-model="searchKeywords"
            />
            <div class="search__icon"></div>
            <div
              class="selected-filters__filter search__placeholder"
              v-if="searchPlaceholder"
            >
              <div class="search__placeholder-content">
                {{ searchPlaceholder }}
                <div
                  class="selected-filters__remove"
                  @click="removeSearch()"
                ></div>
              </div>
            </div>
            <div class="search__splitter"></div>
          </div>
          <div
            class="search__filter"
            ref="filterToggler"
            @click="showSearchFilter = true"
          ></div>
          <SearchFilter
            v-if="showSearchFilter"
            :filterToggler="$refs.filterToggler"
            :toggleSearchFilter="
              (state) => {
                showSearchFilter = state;
              }
            "
          />
        </form>
        <button
          type="button"
          class="button button--default"
          @click="showAddCustomerModal()"
        >
          Add Customer
        </button>
      </div>
    </div>
    <div class="customers__content">
      <Loading v-if="customersLoading" />
      <CustomersTable v-else :customers="customersPerPage.data" />
    </div>
    <Pagination
        :limit="3"
      :data="customersPerPage"
      @pagination-change-page="getResults"
    ></Pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomersTable from "@/components/CustomersTable";
import Loading from "@/partials/Loading";
import SearchFilter from "@/partials/SearchFilter";
export default {
  name: "Customers",

  components: {
    CustomersTable,
    Loading,
    SearchFilter,
  },

  data() {
    return {
      searchKeywords: "",
      showSearchFilter: false,
      selectedAgents: [],
      selectedCompanyTypes: [],
      selectedCompanies: [],
      selectedBranchCategories: [],
      selectedPolicyStatuses: [],
      searchPlaceholder: "",
    };
  },

  watch: {
    "$route.query": {
      handler(query) {
        if (!parseInt(this.$route.query.page)) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              page: 1,
            },
          });
        }

        this.searchPlaceholder = query.search;

        this.searchKeywords = query.search || "";
        this.selectedAgents = query.agents
          ? query.agents.split(",").map((id) => parseInt(id))
          : [];

        this.selectedCompanyTypes = query.companyTypes
          ? query.companyTypes.split(",").map((id) => parseInt(id))
          : [];

        this.selectedCompanies = query.companies
          ? query.companies.split(",").map((id) => parseInt(id))
          : [];

        this.selectedBranchCategories = query.branchCategories
          ? query.branchCategories.split(",").map((id) => parseInt(id))
          : [];

        this.selectedPolicyStatuses = query.policyStatuses
          ? query.policyStatuses.split(",").map((id) => parseInt(id))
          : [];
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapGetters([
      "customersPerPage",
      "customersLoading",
      "searchCustomerForms",
      "language",
    ]),
  },

  async created() {
    this.$store.commit("setCustomersLoading", true);

    await this.$store.dispatch("getSearchCustomerForms");

    await this.$store.dispatch("searchCustomers", {
      page: this.$route.query.page,
      search: this.searchKeywords,
      agents: this.selectedAgents,
      companyTypes: this.selectedCompanyTypes,
      companies: this.selectedCompanies,
      branchCategories: this.selectedBranchCategories,
      policyStatuses: this.selectedPolicyStatuses,
      birthday: this.$route.query.birthday,
      startDate: this.$route.query.startDate,
      endDate: this.$route.query.endDate,
      zip: this.$route.query.zip,
    });

    this.$store.commit("setCustomersLoading", false);
  },

  methods: {
    async loadStateToRouteQuery() {
      this.$store.commit("setCustomersLoading", true);
      this.$router.replace({
        query: {
          page: 1,
          search: this.searchKeywords,
          agents: this.selectedAgents.join(","),
          companyTypes: this.selectedCompanyTypes.join(","),
          companies: this.selectedCompanies.join(","),
          branchCategories: this.selectedBranchCategories.join(","),
          policyStatuses: this.selectedPolicyStatuses.join(","),
          birthday: this.birthday,
          startDate: this.startDate,
          endDate: this.endDate,
          zip: this.zip,
        },
      });
      await this.$store.dispatch("searchCustomers", {
        page: this.$route.query.page,
        search: this.searchKeywords,
        agents: this.selectedAgents,
        companyTypes: this.selectedCompanyTypes,
        companies: this.selectedCompanies,
        branchCategories: this.selectedBranchCategories,
        policyStatuses: this.selectedPolicyStatuses,
        birthday: this.$route.query.birthday,
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
        zip: this.$route.query.zip,
      });
      this.$store.commit("setCustomersLoading", false);
    },

    removeAgent(id) {
      this.selectedAgents.splice(this.selectedAgents.indexOf(id), 1);
      this.loadStateToRouteQuery();
    },

    removeCompanyType(id) {
      this.selectedCompanyTypes.splice(
        this.selectedCompanyTypes.indexOf(id),
        1
      );
      this.loadStateToRouteQuery();
    },

    removeCompany(id) {
      this.selectedCompanies.splice(this.selectedCompanies.indexOf(id), 1);
      this.loadStateToRouteQuery();
    },

    removeBranchCategory(id) {
      this.selectedBranchCategories.splice(
        this.selectedBranchCategories.indexOf(id),
        1
      );
      this.loadStateToRouteQuery();
    },

    removePolicyStatus(id) {
      this.selectedPolicyStatuses.splice(
        this.selectedPolicyStatuses.indexOf(id),
        1
      );
      this.loadStateToRouteQuery();
    },

    async keywordsSearch() {
      await this.$store.dispatch("searchCustomers", {
        search: this.searchKeywords,
        agents: this.selectedAgents,
        companyTypes: this.selectedCompanyTypes,
        companies: this.selectedCompanies,
        branchCategories: this.selectedBranchCategories,
        policyStatuses: this.selectedPolicyStatuses,
        birthday: this.$route.query.birthday,
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
        zip: this.$route.query.zip,
      });
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: this.searchKeywords,
        },
      });
    },

    async removeSearch() {
      await this.$store.dispatch("searchCustomers", {
        search: "",
        agents: this.selectedAgents,
        companyTypes: this.selectedCompanyTypes,
        companies: this.selectedCompanies,
        branchCategories: this.selectedBranchCategories,
        policyStatuses: this.selectedPolicyStatuses,
        birthday: this.$route.query.birthday,
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
        zip: this.$route.query.zip,
      });
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: "",
        },
      });
      this.searchKeywords = "";
      this.$refs.search.focus();
    },

    async getResults(page) {
      if (parseInt(this.$route.query.page) !== page) {
        this.$store.commit("setCustomersLoading", true);
        this.$router.replace({
          query: {
            ...this.$route.query,
            page,
          },
        });
        await this.$store.dispatch("searchCustomers", {
          page: this.$route.query.page,
          search: this.searchKeywords,
          agents: this.selectedAgents,
          companyTypes: this.selectedCompanyTypes,
          companies: this.selectedCompanies,
          branchCategories: this.selectedBranchCategories,
          policyStatuses: this.selectedPolicyStatuses,
          birthday: this.$route.query.birthday,
          startDate: this.$route.query.startDate,
          endDate: this.$route.query.endDate,
          zip: this.$route.query.zip,
        });
        this.$store.commit("setCustomersLoading", false);
      }
    },
    showAddCustomerModal() {
      this.$store.commit("setShowAddCustomerModal", true);
      this.$store.commit("setIsAddingCustomer", true);
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  &__right {
    flex: 3 !important;
  }
}
.customers {
  height: 100%;

  &__content {
    margin-bottom: 20px;
    height: calc(100% - 48px - 15px - 16px - 20px);
  }
}
</style>
