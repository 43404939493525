<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--user"></div>

      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">


        <form class="add__form">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <transition name="fade" mode="out-in">
                <div class="details" key="0" >



                    <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Team name *"
                      v-model="name"
                      :class="{
                        'input--error': nameHasError,
                      }"
                    />
                    <div class="form__error" v-if="nameHasError">
                      {{ nameErrorPlaceholder }}
                    </div>
                  </div>





                    <div class="form__field">
                      <VSelect
                          :options="owners"
                          :reduce="(owner) => owner.id"
                          placeholder="Owner *"
                          label="first_name"
                          v-model="owner.id"
                          class="select select--default"
                          :class="{ 'select--error': ownerHasError }"
                      />
                      <div class="form__error" v-if="ownerHasError">
                        Please select a owner
                      </div>

                  </div>
                </div>

              </transition>

            </div>
          </transition>

        </form>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        type="button"
        class="modal__button modal__button--submit button button--default"
        @click="createTeam()"
      >
        <span >Save</span>

      </button>
    </div>
  </div>
</template>

<script>

import Loading from "@/partials/Loading";
import statics from "@/statics";
import parseFieldErrors from "@/helpers/parseFieldErrors";
import {mapGetters} from "vuex";
export default {
  name: "AddTeamModal",

  components: {
    Loading,
  },

  data() {
    return {
      statics,
      parseFieldErrors,


      name:  "",
      owner : {
        id : "",
        name: ""
      },
      formLoading: false,
      isRequesting: false,
      ownerHasError: false,
      nameHasError: false,
      specificErrors: [],
    };
  },

  watch: {
    "owner.id"(value) {
      if (value) {
        this.ownerHasError = false;
      } else {
        this.ownerHasError = true;
      }
    },
    'name'(value){
      if(value){
        this.nameHasError = false;
      }else {
        this.nameHasError = true;
      }
    }
  },

  async mounted() {
    this.formLoading = true;

    await this.$store.dispatch("getTeamForms");

   if (this.teamIsEdit) {

      this.name = this.teamToEdit.name;
      this.owner.id = this.teamToEdit.owner.id;
      this.id = this.teamToEdit.id;

    }

    this.formLoading = false;
  },

  computed: {
    ...mapGetters(["teamForms", "teamIsEdit", "teamToEdit"]),
    owners() {
      return this.teamForms.owners;
    },
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddTeamModal", false);
      this.$store.commit("setIsEdit", false);
    },
    validateForm() {
        if(!this.name){
          this.nameHasError = true;
        }
        if(!this.owner.id) {
          this.ownerHasError = true;
        }


    },
    async createTeam() {
      this.validateForm();
      if(!this.nameHasError || this.ownerHasError) {
        this.isRequesting = true;


        try {
          const team = {
            owner: this.owner,
            name: this.name,
            id: this.id,
          };
          if (this.teamIsEdit) {
            await this.$store.dispatch("updateTeam", {
              teamId: this.id,
              owner: this.owner,
              name: this.name,
            });
            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Team updated successfully.",
            });
          } else {

            await this.$store.dispatch("createTeam", team);
            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Team added successfully.",
            });
          }
          this.closeModal();

        } catch (err) {
          console.log(err);
          const {errors} = err.response.data;
          this.specificErrors = this.parseFieldErrors(errors);
          this.isRequesting = false;
        }
      }
    },
    loadFieldsForEdit(teamToEdit) {
      const {
        name,
        owner_id,
          id
      } = teamToEdit;

      this.name = name || "",
      this.owner.id = owner_id || "",
      this.team.id = id || ""

    }
  },
};
</script>
