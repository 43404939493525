<template>
  <div class="add modal modal--default">
    <div
      class="modal__panel"
      :class="
        showAddCustomerServicesModal ? 'modal__panel--in' : 'modal__panel--out'
      "
    >
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>
      <div
        class="modal__close"
        @click="$store.commit('setShowAddCustomerServicesModal', false)"
      ></div>

      <div class="add__new">{{ $t(`addCustomerServiceModal.${"title"}`) }}</div>

      <div class="add__title">
        {{ $t(`addCustomerServiceModal.${"subTitle"}`) }}
      </div>

      <div class="customers">
        <div class="customer" @click="showAddPolicyModal()">
          <div class="customer__icon customer__icon--policy"></div>
          <button class="add__button button button--default">
            {{ $t(`addCustomerServiceModal.${"policyButton"}`) }}
          </button>
        </div>
        <div class="customer" @click="showAddMortgageModal()">
          <div class="customer__icon customer__icon--policy"></div>
          <button class="add__button button button--default">
            {{ $t(`addCustomerServiceModal.${"mortgageButton"}`) }}
          </button>
        </div>
        <div class="customer customer--comming-soon">
          <div class="customer__icon customer__icon--activities"></div>
          <button class="add__button button button--default">
            {{ $t(`addCustomerServiceModal.${"activityButton"}`) }}
          </button>
        </div>
        <div class="customer" @click="showPotentialPolicyModal()">
          <div class="customer__icon customer__icon--potential"></div>
          <button class="add__button button button--default">
            {{ $t(`addCustomerServiceModal.${"potentialButton"}`) }}
          </button>
        </div>
        <div class="customer" @click="showTerminationModal()">
          <div class="customer__icon customer__icon--terminate"></div>
          <button class="add__button button button--default">
            {{ $t(`addCustomerServiceModal.${"terminationButton"}`) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AddCustomerServices",

  computed: {
    ...mapGetters(["showAddCustomerServicesModal"]),
  },

  methods: {
    showAddPolicyModal() {
      this.$store.commit("setShowAddCustomerServicesModal", false);
      this.$store.commit("setShowAddPolicyModal", true);
    },
    showAddMortgageModal() {
      this.$store.commit("setShowAddCustomerServicesModal", false);
      this.$store.commit("setShowAddMortgageModal", true);
    },

    showTerminationModal() {
      this.$store.commit("setShowAddCustomerServicesModal", false);
      this.$store.commit("setShowTerminationModal", true);
    },

    showPotentialPolicyModal() {
      this.$store.commit("setShowAddCustomerServicesModal", false);
      this.$store.commit("setShowAddPotentialPolicyModal", true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/_add-panel-modal.scss";
</style>
