import api from "@/api";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    deleteSaleCategoryPrice: async (_, saleCategoryPriceId) => {
      try {   
        await api({ requiresAuth: true }).delete(
          `/v1/sales-category-prices/${saleCategoryPriceId}`
        );
      } catch (err) {
        console.error(err);
      }
    },

    updateSaleCategoryPrice: async (_, { saleCategoryPriceId, provision }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/sales-category-prices/${saleCategoryPriceId}`,
          {
            agent_provision: provision,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
};
