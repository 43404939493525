import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import getPermissions from "@/helpers/getPermissions";
import Toast from "@/partials/Toast";
import vSelect from "vue-select";
import Pagination from "laravel-vue-pagination";
import Datepicker from "vue-flatpickr-component";

// CSS Modules
import "animate.css";
import "leaflet/dist/leaflet.css";
import "@/assets/scss/_index.scss";

Vue.config.productionTip = false;

Vue.component("Toast", Toast);
Vue.component("Pagination", Pagination);
Vue.component("VSelect", vSelect);
Vue.component("Datepicker", Datepicker);

Vue.prototype.$checkRolePermission = (permission) =>
  permission === "default"
    ? true
    : getPermissions().some((p) => p.title === permission);

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!localStorage.getItem("access_token")) {
      next({ name: "Login" });
    }else if(to.name != "AccountDetails" && localStorage.getItem('needs_reset') == true)
    {
     alert("You should update your account first")
    }
    else {
      if (Vue.prototype.$checkRolePermission(to.meta.permission)) {
        next();
      } else {
        next({
          name: "Dashboard",
        });
      }
      next();
    }
  } else if (to.name === "Login" && localStorage.getItem("access_token")) {
    next({
      name: "Dashboard",
    });
  } else {
    next();
  }
});

new Vue({
  render: (h) => h(App),
  store,
  i18n,
  router,
}).$mount("#app");
