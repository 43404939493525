<template>
  <div class="report-wrapper">
    <template v-if="!report.policy_service">
      <div class="report">
        <div class="report__data report__data--small">
          {{ formatDisplayDate(report.date) }}
        </div>
        <div class="report__data report__data--medium">{{ report.agent }}</div>
        <div class="report__data report__data--medium">{{ report.type }}</div>
        <div class="report__data report__data--large">
          {{ report.description }}
        </div>
        <!-- <div class="report__data report__data--small">{{ storno }} CHF</div> -->
        <div class="report__data report__data--medium">
          {{ isOpened === 0 ? storno : balance }}
          CHF
        </div>
      </div>
    </template>
    <div class="report" v-else>
      <div class="report__data report__data--medium">
        {{ formatDisplayDate(report.date) }}
      </div>
      <div class="report__data report__data--large">
        {{ customerName }}
      </div>
      <div class="report__data report__data--large">
        {{ report.company }}
      </div>
      <div class="report__data report__data--medium">
        {{ insuranceType }}
      </div>
      <div class="report__data report__data--medium">
        {{ report.policy_number }}
      </div>
      <div class="report__data report__data--medium">
        {{ report.agent }}
      </div>
      <div class="report__data report__data--medium">
        {{ report.type }}
      </div>
      <!-- <div class="report__data report__data--medium">{{ storno }} CHF</div> -->
      <div class="report__data report__data--medium">
        {{ isOpened === 0 ? storno : balance }}
        CHF
      </div>
      <router-link
        class="report__data report__data--last icon icon__right-arrow"
        :to="{
          name: 'AccountDetails',
          params: {
            entity: 'customer',
            id: report.customer.id,
          },
        }"
      ></router-link>
    </div>
  </div>
</template>

<script>
import { formatDisplayDate } from "@/helpers/dateFormatters";
import statics from "@/statics";
export default {
  name: "Report",

  props: {
    report: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      formatDisplayDate,
      statics,
    };
  },

  computed: {
    customerName() {
      return this.report.customer
        ? `${this.report.customer.first_name} ${this.report.customer.last_name}`
        : this.statics.empty;
    },

    insuranceType() {
      if (this.report.policy_service) {
        return this.report.policy_service.length
          ? this.report.policy_service[0].branch_category_service.code
          : this.statics.empty;
      }
      return this.statics.empty;
    },

    storno() {
      if (this.report.policy_service) {
        return this.report.policy_service.length
          ? this.report.policy_service[0].policy_service_payment.agent
          : this.statics.empty;
      } else {
        return this.report.debit || this.statics.empty;
      }
    },

    isOpened() {
      if (this.report.policy_service) {
        return this.report.policy_service[0].policy_service_payment.paid;
      }
      return false;
    },

    balance() {
      return this.report.balance;
    },
  },
};
</script>

<style scoped lang="scss">
.report {
  margin-bottom: 15px;
  border-radius: var(--radius-1);
  background: var(--bg-1);
  box-shadow: 0px 0px 15px var(--shadow-4);
  position: relative;
  z-index: 1;
}
</style>
