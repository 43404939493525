<template>
  <div class="settings">
    <div class="page__header">
      <div class="page__title">settings</div>
    </div>

    <div class="settings__settings">
      <div
        class="setting box box--default"
        :class="{ 'setting--general': showGeneralSetting }"
      >
        <div class="label label--gradient">General</div>

        <div
          class="setting__chevron"
          :class="{ 'setting__chevron--active': showGeneralSetting }"
          @click="showGeneralSetting = !showGeneralSetting"
        ></div>

        <div class="setting__content">
          <div class="setting__section">
            <div class="setting__title">Theme</div>

            <div class="themes">
              <div class="theme">
                <div class="theme__icon theme__icon--light"></div>
                <div class="theme__title">light mode</div>
              </div>
              <div class="theme">
                <div class="theme__icon theme__icon--dark"></div>
                <div class="theme__title">dark mode</div>
              </div>
            </div>
          </div>

          <div class="setting__section">
            <div class="setting__title">Language</div>

            <VSelect
              class="select select--default select--shadow"
              :options="languages"
              :value="language"
              @input="changeLanguage($event)"
            />
          </div>

          <div class="setting__section">
            <div class="setting__title">Notification</div>

            <div class="setting__options">
              <div class="setting__option">
                <div class="radio">
                  <input
                    class="radio__radio"
                    type="radio"
                    name="notification"
                    id="email"
                  />
                  <label class="radio__label" for="email"></label>
                </div>
                <label for="email">Email</label>
              </div>

              <div class="setting__option">
                <div class="radio">
                  <input
                    class="radio__radio"
                    type="radio"
                    name="notification"
                    id="sms"
                  />
                  <label for="sms" class="radio__label"></label>
                </div>
                <label for="sms">SMS</label>
              </div>

              <div class="setting__option">
                <div class="radio">
                  <input
                    class="radio__radio"
                    type="radio"
                    name="notification"
                    id="appSetting"
                  />
                  <label for="appSetting" class="radio__label"></label>
                </div>
                <label for="appSetting">App</label>
              </div>
            </div>
          </div>

          <div class="setting__section">
            <div class="setting__title">Sort</div>

            <div class="setting__options">
              <div class="setting__option">
                <div class="radio">
                  <input
                    class="radio__radio"
                    type="radio"
                    name="sort"
                    id="firstNameLastName"
                  />
                  <label for="firstNameLastName" class="radio__label"></label>
                </div>
                <label for="firstNameLastName">First name, Last name</label>
              </div>

              <div class="setting__option">
                <div class="radio">
                  <input
                    class="radio__radio"
                    type="radio"
                    name="sort"
                    id="lastNameFirstName"
                  />
                  <label for="lastNameFirstName" class="radio__label"></label>
                </div>
                <label for="lastNameFirstName">Last name, First name</label>
              </div>
            </div>
          </div>

          <div class="setting__buttons">
            <div
              v-if="isRequesting"
              class="button button--default button--loading"
            ></div>
            <button v-else class="button button--default" @click="saveSettings">
              Save
            </button>
          </div>
        </div>
      </div>

      <div
        class="setting box box--default"
        :class="{ 'setting--user': showUserSetting }"
      >
        <div class="label label--gradient">User</div>

        <div
          class="setting__chevron"
          :class="{ 'setting__chevron--active': showUserSetting }"
          @click="showUserSetting = !showUserSetting"
        ></div>

        <div class="setting__content">
          <div class="setting__section">
            <router-link
              :to="{ name: 'PriceCategories' }"
              class="input input--default input--shadow input--static input--edit"
            >
              Price Category
            </router-link>
          </div>

          <div class="setting__section">
            <router-link
              :to="{ name: 'UserRights' }"
              class="input input--default input--shadow input--static input--edit"
            >
              User Rights
            </router-link>
          </div>
        </div>
      </div>

      <div
        class="setting box box--default"
        :class="{ 'setting--system': showSystemSetting }"
      >
        <div class="label label--gradient">System</div>

        <div
          class="setting__chevron"
          :class="{ 'setting__chevron--active': showSystemSetting }"
          @click="showSystemSetting = !showSystemSetting"
        ></div>

        <div class="setting__content">
          <div class="setting__section">
            <router-link
                :to="{ name: 'Teams' }"
                class="input input--default input--shadow input--static input--edit"
            >
             Teams
            </router-link>
          </div>

          <div class="setting__section">
            <div
              class="input input--default input--shadow input--static input--characters"
            >
              Text Settings
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Settings",

  data() {
    return {
      showGeneralSetting: false,
      showUserSetting: false,
      showSystemSetting: false,
      languages: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
      isRequesting: false,
    };
  },

  computed: {
    ...mapGetters(["language"]),
  },

  methods: {
    changeLanguage(value) {
      this.$store.commit("setLanguage", value);
    },

    async saveSettings() {
      try {
        this.isRequesting = true;
        await this.$store.dispatch("saveSettings", {
          userId: JSON.parse(window.localStorage.getItem("user")).id,
          locale: this.language,
        });
        if (this.$root.$i18n.locale !== this.language) {
          window.localStorage.setItem("language", this.language);
          this.$root.$i18n.locale = this.language;
        }
        this.isRequesting = false;
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.settings {
  &__settings {
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
  }

  .setting {
    width: 400px;
    background: var(--bg-1);
    margin-right: 40px;
    position: relative;

    &__content {
      max-height: 0px;
      opacity: 0;
      overflow: hidden;
      padding: 55px 20px 35px 20px;
      transition: all 0.3s ease;
    }

    &--general {
      .setting__content {
        max-height: 577px;
      }
    }

    &--user,
    &--system {
      .setting__content {
        max-height: 232px;
      }
    }

    &--general,
    &--user,
    &--system {
      .setting__content {
        opacity: 1;
      }
    }

    &__chevron {
      position: absolute;
      z-index: 2;
      right: 20px;
      top: 20px;
      width: 30px;
      height: 30px;
      background: url("../assets/icons/default/Bold 2px-chevron_down.svg")
        no-repeat center;
      background-size: 100%;
      cursor: pointer;
      transition: all 0.3s ease;

      &--active {
        transform: rotateX(180deg);
      }
    }

    &__section {
      padding: 15px 0;
      border-bottom: 1px solid var(--border-15);
    }

    &__title {
      color: var(--text-2);
      font-size: var(--x-small);
      font-family: var(--muli-text-semi-bold);
      margin-bottom: 20px;
    }

    .themes {
      display: flex;

      .theme {
        cursor: pointer;

        &:first-child {
          padding-right: 15px;
          border-right: 1px solid var(--border-6);
        }

        &:nth-child(2) {
          padding-left: 15px;
        }

        &__icon {
          height: 24px;
          width: 24px;
          margin: 5px auto;

          &--light {
            background: url("../assets/icons/default/lightmode_icon.svg");
          }

          &--dark {
            background: url("../assets/icons/default/darkmode_icon.svg");
          }
        }

        &__title,
        &__icon {
          transition: all 0.3s ease;
        }

        &__title {
          font-size: var(--x-smaller);
        }

        &:hover {
          .theme__title {
            color: var(--text-5);
          }

          .theme__icon--dark {
            background: url("../assets/icons/default/darkmode_hover_icon.svg");
          }

          .theme__icon--light {
            background: url("../assets/icons/default/lightmode_hover_icon.svg");
          }
        }
      }
    }

    .input {
      &--static {
        display: flex;
        align-items: center;
      }

      &--edit,
      &--characters {
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) center;
      }

      &--edit {
        background-image: url("../assets/icons/default/edit_icon.svg");
      }

      &--characters {
        background-image: url("../assets/icons/default/text_settings_icon.svg");
      }
    }

    &__buttons {
      margin-top: 40px;
      .button {
        width: 135px;
      }
    }

    &__options {
      display: flex;
    }

    &__option {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .radio {
        margin-right: 5px;
      }
      label {
        font-size: var(--x-small);
        color: var(--text-2);
      }
    }
  }
}
</style>
