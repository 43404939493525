<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel modal__panel--sales">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--setting"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">Create New Sale Category</div>
          </div>
        </div>
        <div class="add__form">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <div class="form__field">
                <input
                  type="text"
                  class="input input--default"
                  :class="{ 'input--error': titleHasError }"
                  placeholder="Title"
                  v-model="title"
                />
                <div class="form__error" v-if="titleHasError">
                  Please enter a title
                </div>
              </div>
              <div class="form__field">
                <input
                  type="text"
                  class="input input--default"
                  :class="{ 'input--error': provisionHasError }"
                  placeholder="Standard percentage"
                  v-model="provision"
                />
                <div class="form__error" v-if="provisionHasError">
                  Please enter a valid percentage
                </div>
              </div>
              <div class="form__field">
                <VSelect
                  class="select select--default"
                  :class="{ 'select--error': teamHasError }"
                  :options="teams"
                  :reduce="(team) => team.id"
                  v-model="teamId"
                  label="name"
                  placeholder="Team"
                />
                <div class="form__error" v-if="teamHasError">
                  Please select a team
                </div>
              </div>

              <div class="categories">
                <div class="categories__header">
                  <div class="categories__column categories__column--branch">
                    Branch
                  </div>
                  <div class="categories__column categories__column--agent">
                    Agent
                  </div>
                  <div class="categories__column categories__column--company">
                    Company
                  </div>
                </div>
                <div class="categories__body">
                  <div
                    class="category"
                    v-for="category in categories"
                    :key="category.id"
                  >
                    <div class="categories__column categories__column--branch">
                      <span>{{ category.branch }}</span>
                    </div>
                    <div class="categories__column categories__column--agent">
                      {{ category.agentProvision }}%
                    </div>
                    <div class="categories__column categories__column--company">
                      {{ category.companyProvision }}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        class="modal__button modal__button--submit button button--default"
        @click="addSaleCategory()"
      >
        <span>Add</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/partials/Loading";
export default {
  name: "AddSaleCategoryModal",

  components: {
    Loading,
  },

  data() {
    return {
      formLoading: false,
      title: "",
      provision: "",
      teamId: 0,
      categories: [],
      isRequesting: false,

      titleHasError: false,
      provisionHasError: false,
      teamHasError: false,
    };
  },

  watch: {
    title() {
      this.validateTitle();
    },

    provision() {
      this.validateProvision();
    },

    teamId() {
      this.validateTeam();
    },
  },

  computed: {
    ...mapGetters(["saleCategoriesData", "saleCategoryTeamId", "language"]),

    branchCategories() {
      return this.saleCategoriesData["branch-categories"];
    },

    teams() {
      return this.saleCategoriesData.teams;
    },
  },

  async created() {
    this.formLoading = true;
    this.categories = this.branchCategories.map((category) => ({
      branch: category[`long_${this.language}`],
      agentProvision: 0,
      companyProvision: 0,
    }));
    this.formLoading = false;
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddSaleCategoryModal", false);
    },

    validateProvision() {
      let decimalProvision = "";
      if (this.provision && this.provision.includes(".")) {
        decimalProvision = this.provision.split(".")[1];
      }

      if (
        this.provision &&
        this.provision <= 100 &&
        this.provision > 0 &&
        decimalProvision.length <= 4
      ) {
        this.provisionHasError = false;
        this.categories.forEach((category) => {
          category.agentProvision = this.provision;
          category.companyProvision = 100 - this.provision;
        });
      } else {
        this.provisionHasError = true;
        this.categories.forEach((category) => {
          category.agentProvision = 0;
          category.companyProvision = 0;
        });
      }
    },

    validateTitle() {
      if (!this.title) {
        this.titleHasError = true;
      } else {
        this.titleHasError = false;
      }
    },

    validateTeam() {
      if (!this.teamId) {
        this.teamHasError = true;
      } else {
        this.teamHasError = false;
      }
    },

    async addSaleCategory() {
      this.isRequesting = true;
      this.validateTitle();
      this.validateProvision();
      this.validateTeam();
      if (
        !this.titleHasError &&
        !this.provisionHasError &&
        !this.teamHasError
      ) {
        await this.$store.dispatch("addSaleCategory", {
          title: this.title,
          provision: this.provision,
          teamId: this.teamId,
        });
        if (
          this.saleCategoryTeamId &&
          this.$checkRolePermission("team_access")
        ) {
          await this.$store.dispatch(
            "getSaleCategoriesByTeam",
            this.saleCategoryTeamId
          );
        } else {
          await this.$store.dispatch("getSaleCategories");
        }
        this.closeModal();
      }
      this.isRequesting = false;
    },
  },
};
</script>

<style scoped lang="scss">
.modal__button {
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
}

.add {
  &__form {
    width: 100% !important;
  }
}

.form__field {
  width: 280px !important;
  margin: 20px auto;
}

.categories {
  width: 570px;
  height: 380px;
  box-shadow: 0px 2px 4px var(--shadow-1);
  border-radius: var(--radius-1);
  margin: auto;
  padding: 5px 0;

  &__column {
    padding: 5px 0;

    &--branch,
    &--agent {
      border-right: 1px solid var(--border-6);
    }

    &--branch {
      flex: 1;
    }

    &--agent {
      flex: 4;
      text-align: right;
      padding-right: 40px;
    }

    &--company {
      flex: 2;
      padding-left: 50px;
    }
  }

  &__header {
    box-shadow: 0px 2px 4px var(--shadow-1);
    border-radius: inherit;
    color: var(--text-2);
    font-size: var(--x-smaller);
  }

  &__body {
    margin-top: 5px;
    height: calc(100% - 38px - 5px);
    overflow: auto;
  }

  &__header,
  .category {
    padding: 8px 20px;
    display: flex;
  }

  .category {
    color: var(--text-9);
    font-size: var(--x-small);
    border-bottom: 1px solid var(--border-15);
    position: relative;

    &:first-child {
      border-top: 1px solid var(--border-15);
    }

    .categories__column {
      border: 0;

      &--branch {
        span {
          white-space: nowrap;
          position: absolute;
        }
      }
    }
  }
}
</style>
