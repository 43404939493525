import { formatDate } from "../../helpers/dateFormatters";
import statics from "@/statics";
import api from "@/api";
import moment from "moment";

export default {
  state: {
    formModels: [],
    singletonFormModels: [],
    singleModel: {
      id: "",
      serviceId: "",
      startDate: "",
      endDate: formatDate(statics.lastDateOfCurrentYear),
      franchise: "",
      accident: "",
      doctorName: "",
      price: "",
      interval: "",
      startDateHasError: false,
      priceHasError: false,
    },
    models: [],
  },
  getters: {
    formModels: (state) => state.formModels,
    singleModel: (state) => state.singleModel,
    singletonFormModels: (state) => state.singletonFormModels,
    models: (state) => state.models,
  },
  mutations: {
    setFormModels: (state, data) => {
      state.formModels = data;
    },
    setSingleModel: (state, data) => {
      state.singleModel = data;
    },
    setSpecificFormModel: (state, { index, key, data }) => {
      if (key === "startDate") {
        if (!data) {
          state.formModels[index].startDateHasError = true;
        } else {
          state.formModels[index].startDateHasError = false;
        }
      }

      if (key === "endDate") {
        if (moment(state.formModels[index].startDate).isAfter(moment(data))) {
          state.formModels[index].startDateHasError = true;
        } else {
          state.formModels[index].startDateHasError = false;
        }
      }

      if (key === "price") {
        if (
            (!data ||
          data < 1 ||
          statics.stringRegex.test(data) ||
          statics.symbolRegex.test(data) )
        ) {
          state.formModels[index].priceHasError = true;

        } else {
          state.formModels[index].priceHasError = false;
        }
      }
      state.formModels[index][key] = data;
    },

    setSpecificSingleModel: (state, { key, data }) => {
      if (key === "startDate") {
        if (!data || moment(data).isAfter(moment(state.singleModel.endDate))) {
          state.singleModel.startDateHasError = true;
        } else {
          state.singleModel.startDateHasError = false;
        }
      }
      if (key === "price") {
        if (
          !data ||
          data < 1 ||
          statics.stringRegex.test(data) ||
          statics.symbolRegex.test(data)
        ) {
          state.singleModel.priceHasError = true;
        } else {
          state.singleModel.priceHasError = false;
        }
      }
      state.singleModel[key] = data;
    },
    setSingletonFormModels: (state, data) => {
      state.singletonFormModels = data;
    },
    setSpecificSingletonFormModel: (state, { index, key, data }) => {
      if (key === "startDate") {
        if (!data) {
          state.singletonFormModels[index].startDateHasError = true;
        } else {
          state.singletonFormModels[index].startDateHasError = false;
        }
      }

      if (key === "endDate") {
        if (
          moment(state.singletonFormModels[index].startDate).isAfter(
            moment(data)
          )
        ) {
          state.singletonFormModels[index].startDateHasError = true;
        } else {
          state.singletonFormModels[index].startDateHasError = false;
        }
      }

      if (key === "price") {
        if (
          !data ||
          data < 1 ||
          statics.stringRegex.test(data) ||
          statics.symbolRegex.test(data)
        ) {
          state.singletonFormModels[index].priceHasError = true;
        } else {
          state.singletonFormModels[index].priceHasError = false;
        }
      }
      state.singletonFormModels[index][key] = data;
    },
    setModels: (state, data) => {
      state.models = data;
    },
  },
  actions: {
    getModelsByCompanyServiceAndCustomer: (
      _,
      { companyId, serviceId, customerId }
    ) => {
      return new Promise((resolve, reject) => {
        api({ requiresAuth: true })
          .get(
            `/v1/companies/${companyId}/services/${serviceId}/customers/${customerId}/models`
          )
          .then(
            (response) => {
              const { data } = response.data;
              resolve(data);
            },
            (error) => {
              console.error(error);
              reject(error);
            }
          );
      });
    },

    getModelsByCompanyAndServices: (_, { companyId, serviceId }) => {
      return new Promise((resolve, reject) => {
        api({ requiresAuth: true })
          .get(`/v1/companies/${companyId}/services/${serviceId}/models`)
          .then(
            (response) => {
              const { data } = response.data;
              resolve(data);
            },
            (error) => {
              console.error(error);
              reject(error);
            }
          );
      });
    },

    deleteModel: async (_, modelId) => {
      try {
        await api({ requiresAuth: true }).delete(
          `/v1/service-models/${modelId}`
        );
      } catch (err) {
        console.error(err);
      }
    },

    updateModel: async (
      { commit },
      { name, modelId, companyId, serviceId }
    ) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/service-models/${modelId}`,
          {
            name,
            company_id: companyId,
            service_id: serviceId,
          }
        );
        commit("setToast", {
          display: true,
          type: "success",
          message: "Model updated successfully.",
        });
      } catch (err) {
        console.error(err);
      }
    },

    addModel: async (
      { commit },
      { companyId, serviceId, name, canton, region, categories }
    ) => {
      try {
        await api({ requiresAuth: true }).post("/v1/service-models", {
          company_id: companyId,
          service_id: serviceId,
          name,
          canton,
          region,
          model_categories: categories,
        });
        commit("setToast", {
          display: true,
          type: "success",
          message: "Model added successfully.",
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
