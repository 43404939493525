<template>
  <div class="dashboard">
    <NoData :type="'customers'" />
    <!-- <div class="dashboard__top"><CustomersTable /></div>
    <div class="dashboard__bottom">
      <Calendar />
      <PotentialCustomers />
      <Notes />
    </div> -->
  </div>
</template>

<script>
// import CustomersTable from "@/components/CustomersTable";
// import Calendar from "@/components/Dashboard/Calendar";
// import PotentialCustomers from "@/components/Dashboard/PotentialCustomers";
// import Notes from "@/components/Dashboard/Notes";
import NoData from "@/partials/NoData";
export default {
  name: "Dashboard",

  components: {
    // CustomersTable,
    // Calendar,
    // PotentialCustomers,
    // Notes,
    NoData,
  },
};
</script>

<style scoped lang="scss">
$bottomHeight: 432px;
.dashboard {
  height: 100%;
  position: relative;

  &__top {
    margin-bottom: 20px;
    height: calc(100% - #{$bottomHeight} - 20px);
    overflow: auto;
  }

  &__bottom {
    height: $bottomHeight;
    display: flex;
  }
}
</style>
