export default {
  state: {
    language: null,
    showAddCustomerModal: false,
    showAddCompanyModal: false,
    showAddPrivateModal: false,
    showAddUserModal: false,
    showAddTeamModal: false,
    deleteModal: {
      display: false,
      message: "",
      action: null,
      id: null,
    },
    showAddServiceModal: false,
    showAddPermissionModal: false,
    showAddRoleModal: false,
    usersLoading: false,
    teamsLoading: false,
    customersLoading: false,
    isAddingCustomer: false,
    toast: {
      display: false,
      type: null,
      title: null,
      message: null,
    },
    isEdit: false,
    userToEdit: {},
    companyIsEdit: false,
    companyToEdit: {},
    privateIsEdit: false,
    privateToEdit: {},
    teamIsEdit: false,
    teamToEdit: {},
    showAddCustomerServicesModal: false,
    showAddPolicyModal: false,
    showAddMortgageModal: false,
    showPolicyFilesModal: false,
    showMortgageFilesModal: false,
    showTerminationModal: false,
    policyFilesModalIsTermination: false,
    policyFilesModalIsPotential: false,
    showAddModelModal: false,
    addCompanyContactModal: false,
    addSaleCategoryModal: false,
    showAddPotentialPolicyModal: false,
    policyIsConverting: false,
    convertingPolicyData: {
      agentId: "",
      text: "",
    },
    isAddingMember: false,
    contactToEdit: {},
    showSearchPotentialCustomerModal: false,
    isAddingPotentialCustomer: false,
    showPolicyProvisionModal: false,
    showAddAccidentModal: false,
    policyFilesModalIsAccident: false,
    policyFilesModalIsAccidentChildren: false,
    policyFilesModalIsMortgage: false,
    showAddInvoiceModal: false,
    showAddTransactionModal: false,
    showPayoutReviewModal: false,
    payoutReviewModalData: {},
    showResetPasswordModal: false,
  },
  getters: {
    language: (state) => state.language,
    showAddCustomerModal: (state) => state.showAddCustomerModal,
    deleteModal: (state) => state.deleteModal,
    showAddServiceModal: (state) => state.showAddServiceModal,
    showAddPermissionModal: (state) => state.showAddPermissionModal,
    showAddRoleModal: (state) => state.showAddRoleModal,
    usersLoading: (state) => state.usersLoading,
    customersLoading: (state) => state.customersLoading,
    teamsLoading: (state) => state.teamsLoading,
    toast: (state) => state.toast,
    isAddingCustomer: (state) => state.isAddingCustomer,
    showAddCompanyModal: (state) => state.showAddCompanyModal,
    showAddPrivateModal: (state) => state.showAddPrivateModal,
    showAddUserModal: (state) => state.showAddUserModal,
    showAddTeamModal: (state) => state.showAddTeamModal,
    userToEdit: (state) => state.userToEdit,
    isEdit: (state) => state.isEdit,
    companyIsEdit: (state) => state.companyIsEdit,
    companyToEdit: (state) => state.companyToEdit,
    privateIsEdit: (state) => state.privateIsEdit,
    privateToEdit: (state) => state.privateToEdit,
    teamIsEdit: (state) => state.teamIsEdit,
    teamToEdit: (state) => state.teamToEdit,
    showAddCustomerServicesModal: (state) => state.showAddCustomerServicesModal,
    showAddMortgageModal: (state) => state.showAddMortgageModal,
    showAddPolicyModal: (state) => state.showAddPolicyModal,
    showPolicyFilesModal: (state) => state.showPolicyFilesModal,
    showMortgageFilesModal: (state) => state.showMortgageFilesModal,
    showTerminationModal: (state) => state.showTerminationModal,
    policyFilesModalIsTermination: (state) =>
      state.policyFilesModalIsTermination,
    showAddModelModal: (state) => state.showAddModelModal,
    addCompanyContactModal: (state) => state.addCompanyContactModal,
    showAddSaleCategoryModal: (state) => state.addSaleCategoryModal,
    showAddPotentialPolicyModal: (state) => state.showAddPotentialPolicyModal,
    policyFilesModalIsPotential: (state) => state.policyFilesModalIsPotential,
    policyIsConverting: (state) => state.policyIsConverting,
    convertingPolicyData: (state) => state.convertingPolicyData,
    isAddingMember: (state) => state.isAddingMember,
    contactToEdit: (state) => state.contactToEdit,
    showSearchPotentialCustomerModal: (state) =>
      state.showSearchPotentialCustomerModal,
    isAddingPotentialCustomer: (state) => state.isAddingPotentialCustomer,
    showPolicyProvisionModal: (state) => state.showPolicyProvisionModal,
    showAddAccidentModal: (state) => state.showAddAccidentModal,
    policyFilesModalIsAccident: (state) => state.policyFilesModalIsAccident,
    policyFilesModalIsAccidentChildren: (state) =>
      state.policyFilesModalIsAccidentChildren,
    policyFilesModalIsMortgage: (state) => state.policyFilesModalIsMortgage,
    showAddInvoiceModal: (state) => state.showAddInvoiceModal,
    showAddTransactionModal: (state) => state.showAddTransactionModal,
    showPayoutReviewModal: (state) => state.showPayoutReviewModal,
    payoutReviewModalData: (state) => state.payoutReviewModalData,
    showResetPasswordModal: (state) => state.showResetPasswordModal,
  },
  mutations: {
    setLanguage: (state, data) => {
      state.language = data;
    },
    setShowAddCustomerModal: (state, data) => {
      state.showAddCustomerModal = data;
    },
    setDeleteModal: (state, data) => {
      state.deleteModal = data;
    },
    setShowAddServiceModal: (state, data) => {
      state.showAddServiceModal = data;
    },

    setShowAddPermissionModal: (state, data) => {
      state.showAddPermissionModal = data;
    },
    setShowAddRoleModal: (state, data) => {
      state.showAddRoleModal = data;
    },
    setUsersLoading: (state, data) => {
      state.usersLoading = data;
    },
    setTeamsLoading: (state, data) => {
      state.teamsLoading = data;
    },
    setCustomersLoading: (state, data) => {
      state.customersLoading = data;
    },
    setToast: (state, data) => {
      state.toast = data;
    },
    setIsAddingCustomer: (state, data) => {
      state.isAddingCustomer = data;
    },
    setShowAddCompanyModal: (state, data) => {
      state.showAddCompanyModal = data;
    },
    setShowAddPrivateModal: (state, data) => {
      state.showAddPrivateModal = data;
    },
    setShowAddUserModal: (state, data) => {
      state.showAddUserModal = data;
    },
    setShowAddTeamModal: (state, data) => {
      state.showAddTeamModal = data;
    },
    setUserToEdit: (state, data) => {
      state.userToEdit = data;
    },
    setIsEdit: (state, data) => {
      state.isEdit = data;
    },
    setCompanyIsEdit: (state, data) => {
      state.companyIsEdit = data;
    },
    setCompanyToEdit: (state, data) => {
      state.companyToEdit = data;
    },
    setPrivateIsEdit: (state, data) => {
      state.privateIsEdit = data;
    },
    setPrivateToEdit: (state, data) => {
      state.privateToEdit = data;
    },
    setTeamIsEdit: (state, data) => {
      state.teamIsEdit = data;
    },
    setTeamToEdit: (state, data) => {
      state.teamToEdit = data;
    },
    setShowAddCustomerServicesModal: (state, data) => {
      state.showAddCustomerServicesModal = data;
    },
    setShowAddPolicyModal: (state, data) => {
      state.showAddPolicyModal = data;
    },
    setShowAddMortgageModal: (state, data) => {
      state.showAddMortgageModal = data;
    },

    setShowPolicyFilesModal: (state, data) => {
      state.showPolicyFilesModal = data;
    },
    setShowMortgageFilesModal: (state, data) => {
      state.showMortgageFilesModal = data;
    },

    setShowTerminationModal: (state, data) => {
      state.showTerminationModal = data;
    },

    setPolicyFilesModalIsTermination: (state, data) => {
      state.policyFilesModalIsTermination = data;
    },
    setPolicyFilesModalIsPotential: (state, data) => {
      state.policyFilesModalIsPotential = data;
    },
    setShowAddModelModal: (state, data) => {
      state.showAddModelModal = data;
    },
    setShowAddCompanyContactModal: (state, data) => {
      state.addCompanyContactModal = data;
    },
    setShowAddSaleCategoryModal: (state, data) => {
      state.addSaleCategoryModal = data;
    },
    setShowAddPotentialPolicyModal: (state, data) => {
      state.showAddPotentialPolicyModal = data;
    },
    setPolicyIsConverting: (state, data) => {
      state.policyIsConverting = data;
    },
    setConvertingPolicyData: (state, data) => {
      state.convertingPolicyData = data;
    },
    setIsAddingMember: (state, data) => {
      state.isAddingMember = data;
    },
    setContactToEdit: (state, data) => {
      state.contactToEdit = data;
    },
    setShowSearchPotentialCustomerModal: (state, data) => {
      state.showSearchPotentialCustomerModal = data;
    },
    setIsAddingPotentialCustomer: (state, data) => {
      state.isAddingPotentialCustomer = data;
    },
    setShowPolicyProvisionModal: (state, data) => {
      state.showPolicyProvisionModal = data;
    },
    setShowAddAccidentModal: (state, data) => {
      state.showAddAccidentModal = data;
    },
    setPolicyFilesModalIsAccident: (state, data) => {
      state.policyFilesModalIsAccident = data;
    },
    setPolicyFilesModalIsAccidentChildren: (state, data) => {
      state.policyFilesModalIsAccidentChildren = data;
    },
    setPolicyFilesModalIsMortgage: (state, data) => {
      state.policyFilesModalIsMortgage = data;
    },
    setShowAddInvoiceModal: (state, data) => {
      state.showAddInvoiceModal = data;
    },
    setShowAddTransactionModal: (state, data) => {
      state.showAddTransactionModal = data;
    },
    setShowPayoutReviewModal: (state, data) => {
      state.showPayoutReviewModal = data;
    },
    setPayoutReviewModalData: (state, data) => {
      state.payoutReviewModalData = data;
    },
    setShowResetPasswordModal: (state, data) => {
      state.showResetPasswordModal = data;
    },
  },
  actions: {},
};
