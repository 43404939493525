<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel modal__panel--mortgage">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--policy" ></div>
      <div class="modal__close" @click="closeModal()"></div>


      <div class="modal__title" >Mortgage documents</div>

      <div class="content">
        <div class="files">
          <div class="files__header">
            <div class="files__label files__label--large">Name</div>
            <div class="files__label files__label--medium">View</div>
            <div class="files__label files__label--medium">Date</div>

            <!-- <div class="files__label files__label--small">Private</div> -->
            <div class="files__label files__label--small">Delete</div>
          </div>
          <div class="files__body">
            <Loading v-if="filesLoading" />
            <template v-else>

              <Document
                v-for="document in mortgageDocuments"
                :key="document.id"
                :document="document"
                :updateFiles="updateFiles"
              />
            </template>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Document from "@/components/AccountDetails/Document";

import Vue from "vue";
import Loading from "@/partials/Loading";
export default {
  name: "MortgageFilesModal",

  components: {
    Document,

    Loading,
  },

  data() {
    return {
      documents: [],
      bus: new Vue(),
      filesLoading: false,
    };
  },

  computed: {
    ...mapGetters([
      "mortgageDocuments",
      "mortgageId",
      "customer",

    ]),
  },

  destroyed() {
/*    this.$store.commit("setPolicyFilesModalIsTermination", false);
    this.$store.commit("setPolicyFilesModalIsPotential", false);
    this.$store.commit("setPolicyFilesModalIsAccident", false);
    this.$store.commit("setPolicyFilesModalIsAccidentChildren", false);*/
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowMortgageFilesModal", false);
    },

    async uploadFiles(event) {
      this.filesLoading = true;
      this.files = event;

      const files = this.files.map((file) => file.file);


        await this.$store.dispatch("addMortgageFile", {
          policyId: this.policyFileId,
          files,
        });

      await this.updateFiles();
      this.bus.$emit("delete");
      this.filesLoading = false;
    },

    async updateFiles() {
      const { id } = this.$route.params;
      await this.$store.dispatch("getGlobalCustomer", id);


      this.$store.commit("setMortgageDocuments", [
        ...this.customer.mortgages.find(
            (mortgage) => mortgage.id ===   this.mortgageId,
        ).documents,
      ]);

    },
  },
};
</script>

<style lang="scss" scoped>
.modal__panel--mortgage {
  height: calc(100% - 200px);
  top: 50px;
}
.modal {
  &__title {
    margin: 53px 0 35px 10px;
    font-size: var(--medium);
    font-family: var(--muli-text-semi-bold);
  }

  .modal__title,
  .files__label,
  ::v-deep.file {
    color: var(--text-2);
  }

  .content {
    display: flex;
    align-items: flex-start;
    height: calc(100% - 53px - 35px - 18px);
  }

  .files {
    width: 100%;
    height: 100%;
    margin-right: 20px;
    font-size: var(--x-small);

    &__header {
      border-bottom: 1px solid var(--border-10);
      display: flex;
      padding: 5px 10px;
      margin-bottom: 8px;
    }

    &__body {
      height: calc(100% - 25px - 8px);
      overflow: auto;
      position: relative;
    }

    ::v-deep.file {
      display: flex;
      padding: 20px 10px;
      background: var(--bg-3);
      margin-bottom: 1px;
      position: relative;
      height: 64px;
      max-height: 64px;
      transition: all 0.3s ease;

      &__data {
        &--large {
          color: var(--text-10);
          text-decoration: underline;
        }
      }

      .icon {
        background-position: left;
      }
    }

    &__label,
    ::v-deep.file__data {
      display: flex;
      align-items: center;
      &--large {
        flex: 3;
      }
      &--medium {
        flex: 2;
      }
      &--small {
        flex: 1;
      }
    }
  }
}
</style>
