<template>
  <div class="team row row--hover box box--default">

    <div
      class="row__data row__data--large row__data--name"
     
    >
      {{ team.name }}
    </div>


    <div
      class="row__data row__data--large row__data--address"
     
    >
      {{ team.owner.first_name }}   {{ team.owner.last_name }}
    </div>

    <div
      class="row__data row__data--action row__data--small"
      @click.self="showActionsDropdown = !showActionsDropdown"
    >
      <RowActionDropdown
        v-if="showActionsDropdown"
        @toggleDropdown="showActionsDropdown = $event"
        :onEdit="editTeam"
        :onDelete="removeTeam"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RowActionDropdown from "@/components/RowActionDropdown";

import { parseInitials } from "@/helpers/entityParsers";
export default {
  name: "Team",

  components: {
    RowActionDropdown,
  },

  props: {
    team: {
      required: true,
      type: Object,
    },

    deleteTeam: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      showActionsDropdown: false,
      parseInitials,
    };
  },

  computed: {
    ...mapGetters(["users", "saleCategories"])




  },

  methods: {


    editTeam() {
      this.$store.commit("setTeamToEdit", this.team);
      this.$store.commit("setTeamIsEdit", true);
      this.$store.commit("setShowAddTeamModal", true);
    },

    removeTeam() {

      this.deleteTeam({
        display: true,
        message: `'${this.team.name}'` ,
        action: this.$store._actions.deleteTeam,
        id: this.team.id,
      });


    },



  },
};
</script>
