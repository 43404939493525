import api from "@/api";

export default {
  state: {
    saleCategories: [],
    saleCategoriesData: {},
    saleCategoryTeamId: 0,
  },
  getters: {
    saleCategories: (state) => state.saleCategories,
    saleCategoriesData: (state) => state.saleCategoriesData,
    saleCategoryTeamId: (state) => state.saleCategoryTeamId,
  },
  mutations: {
    setSaleCategories: (state, data) => {
      state.saleCategories = data;
    },

    setSaleCategoriesData: (state, data) => {
      state.saleCategoriesData = data;
    },

    setSaleCategoryTeamId: (state, data) => {
      state.saleCategoryTeamId = data;
    },
  },
  actions: {
    getSaleCategories: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/sales-categories"
        );
        const { data } = response;
        commit("setSaleCategories", data);
      } catch (err) {
        console.error(err);
      }
    },

    getSaleCategoriesByTeam: async ({ commit }, teamId) => {
      try {
        const { data } = await api({ requiresAuth: true }).get(
          `/v1/teams/${teamId}/sales-categories`
        );
        commit("setSaleCategories", data);
      } catch (err) {
        console.error(err);
      }
    },

    getSaleCategoriesFormData: async ({ commit }) => {
      try {
        const {
          data: { data },
        } = await api({ requiresAuth: true }).get(
          "/v1/partials/sales-categories-forms"
        );
        commit("setSaleCategoriesData", data);
      } catch (err) {
        console.error(err);
      }
    },

    addSaleCategory: async ({ commit }, { title, provision, teamId }) => {
      try {
        await api({ requiresAuth: true }).post("/v1/sales-categories", {
          title,
          agent_provision: provision,
          team_id: teamId,
        });
        commit("setToast", {
          display: true,
          type: "success",
          message: "Sale Category added successfully.",
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
