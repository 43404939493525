<template>
  <div
      class="policy"
      :class="[

      { 'animate__animated animate__zoomOutLeft': isDeleting },
      { deleting: wasDeleted },
        !isLoading ? `policy--${statusColor}` : null,
    ]" >
    <Loading v-if="isLoading" />

    <template v-else>


      <div class="row row--policy box box--default">
      <div
          class="row__data row__data--small row__data--underline"
      >
       {{index + 1}}. {{document.name}}
      </div>


      <div class="row__data row__data--small ">
        <div
            class="row__data row__data--small row__data--pdf icon icon__pdf"
            :class="{ 'icon__pdf--active': document.document_files.length }"
            @click="openPolicyFilesModal()"
        ></div>
      </div>
        <div class="policy-service__data policy-service__data--delete">
      <div
          class="icon icon__delete"
          :class="{ 'icon__delete--active': showDeletePopup }"
          tabindex="-1"
          @focus="showDeletePopup = true"
          @focusout="showDeletePopup = false"
          ref="delete"
          v-if="document.document_file"
      >
        <transition name="fade">
          <div class="delete" v-if="showDeletePopup">
            <div class="delete__text">
              Are you sure you want to delete this File?
            </div>
            <div class="delete__buttons">
              <div
                  class="button button--confirm"
                  @click="deletePolicyFile(document.document_file)"
              ></div>
              <div
                  class="button button--cancel"
                  @click="closeDeletePopup()"
              ></div>
            </div>
          </div>
        </transition>
      </div>
        </div>


    </div>
    </template>
  </div>
</template>

<script>
import { formatDisplayDate } from "@/helpers/dateFormatters";
import statics from "@/statics";
import Loading from "@/partials/Loading";
import { mapGetters } from "vuex";
import { fetchGlobalCustomer } from "@/helpers/accountDetailHelpers";
import moment from "moment";
export default {
  name: "MortgageDocument",

  components: {
    Loading
  },

  props: {
    document: {
      required: true,
      type: Object,
    },

    index: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      fetchGlobalCustomer,
      formatDisplayDate,
      statics,
      showStatusPopup: false,
      isDeleting: false,
      wasDeleted: false,
      showEditablePopup: false,
      isLoading: false,
      showDeletePopup: false,
    };
  },

  computed: {
    ...mapGetters(["mortgageForms"]),
    statusColor() {
        if(this.document.document_files.length > 0)
        {
          return 'active';
        }
      return 'new'
    },




    agents() {
      return this.mortgageForms.agents;
    },

    agent() {
      return this.mortgage.agent
        ? this.mortgage.agent.first_name || statics.empty
        : statics.empty;
    },


  },

  methods: {
    createdAt(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    openSingleFileDialog() {
      const { inputFile } = this.$refs;
      inputFile.click();
    },
    openPolicyFilesModal() {
      this.$store.commit("setPolicyFileId", this.document.id);
      this.$store.commit("setPolicyFiles", this.document.document_files);
      this.$store.commit("setPolicyFilesModalIsMortgage", true);
      this.$store.commit("setShowPolicyFilesModal", true);
      this.$store.commit("setFileModalTitle", this.document.name);

    },
    async   selectSingleFile(event, document) {
      this.isLoading = true;
      await this.$store.dispatch("addMortgageFile", {
        documentId: document.id,
        file: event.target.files[0]
      });
      await this.fetchGlobalCustomer(this.$route.params.id);
      this.isLoading = false;
      /*const { id } = this.$route.params;
      await this.$store.dispatch("getGlobalCustomer", id);
   */

      /*      const { files } = event.target;
            const file = [...files][0];
            const { name } = file;
            const mimeType = this.parseFileExtension(name);
            this.files = [
              ...this.files,
              {
                name,
                type: mimeType,
                file,
              },
            ];
            this.$emit("input", this.files);*/
    },
    parseFileExtension(name) {
      const mimeTypeParts = name.split(".");
      let mimeType = mimeTypeParts[mimeTypeParts.length - 1].toLowerCase();
      if (mimeType === "jpg" || mimeType === "png" || mimeType === "svg") {
        mimeType = "image";
      }
      return mimeType;
    },

    async deletePolicyFile(file) {
      this.closeDeletePopup();
      this.isLoading = true;

      await this.$store.dispatch("deleteFile", file.uuid);
      await this.fetchGlobalCustomer(this.$route.params.id);
      this.isLoading = false;
      setTimeout(async () => {
        await this.updateFiles();
      }, 300);
    },


    async openFile(file) {
      await this.$store.dispatch("openFile", file);
    },

    closeDeletePopup() {
      this.$refs.delete.blur();
      this.showDeletePopup = false;
    },
  },
};
</script>
<style lang="scss" scoped>

.button--upload{
  width: 120px;
  height: 30px;
  font-size: 12px;
}

.add__upload{
  opacity: 0;
  position: absolute;
}
.delete {
  position: absolute;
  z-index: 3;
  background: var(--bg-1);
  right: calc(100% + 5px);
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  display: flex;
  align-items: center;
  border-radius: var(--radius-1);
  border: 1px solid var(--border-2);
  padding: 10px 20px;
  box-shadow: 0px 3px 6px var(--shadow-7);

  &__text {
    line-height: 20px;
  }

  &__buttons {
    display: flex;
  }

  .button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: all 0.3s ease;

    &--confirm {
      background: url("../../assets/icons/default/confirm_icon.svg");
      margin: 0 15px;

      &:hover {
        background: url("../../assets/icons/default/confirm_hover_icon.svg");
      }
    }

    &--cancel {
      background: url("../../assets/icons/default/close_icon.svg");
      box-shadow: none;

      &:hover {
        background: url("../../assets/icons/default/close_hover_icon.svg");
      }
    }
  }
}
</style>