<template>
  <div class="policies">
    <div class="table">
      <div class="table__header box box--default">
        <div class="table__label table__label--medium table__label--fixed">
          Status
        </div>
        <div class="table__label table__label--large">Policennummer</div>
        <div class="table__label table__label--large">Erstellungsdatum</div>
        <div class="table__label table__label--large">Versicherer</div>
        <div class="table__label table__label--large">Branche</div>
        <div class="table__label table__label--medium">Zuständig</div>
        <div
          class="table__label table__label--medium table__label--more-horizontal table__label--last"
        ></div>
      </div>

      <div class="table__body">
        <div
          v-if="!policies.length && !terminations.length"
          class="table__no-data"
        >
          <NoData :type="'policies'" />
        </div>
        <template v-else>
          <Loading v-if="detailsLoading" />
          <template v-else>
            <div class="table__policies">
              <Policy
                v-for="policy in policies"
                :key="policy.id"
                :policy="policy"
              />
            </div>
            <TerminationsTable
              v-if="terminations.length"
              :terminations="terminations"
            />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Policy from "@/components/AccountDetails/Policy";
import Loading from "@/partials/Loading";
import NoData from "@/partials/NoData";
import TerminationsTable from "@/components/AccountDetails/TerminationsTable";
export default {
  name: "PolicyTable",

  components: {
    Policy,
    Loading,
    NoData,
    TerminationsTable,
  },

  props: {
    policies: {
      required: false,
      type: Array,
    },

    terminations: {
      required: true,
      type: Array,
    },

    detailsLoading: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
