import api from "@/api";

export default {
  state: {
    teams: []
  },
  getters: {
    teams: (state) => state.teams,
  },
  mutations: {
    setTeams: (state, data) => {
      state.teams = data;
    },

  },
  actions: {
    getTeams: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get("/v1/teams");
        const { data } = response.data;
        commit("setTeams", data);
      } catch (err) {
        console.error(err);
      }
    },
      deleteTeam: async ({ commit, dispatch }, teamId) => {
          commit("setUsersLoading", true);
          try {
              await api({ requiresAuth: true }).delete(`/v1/teams/${teamId}`);
              await dispatch("getTeams");

          } catch (err) {
              console.error(err);
          }
          commit("setTeamsLoading", false);
      },
    createTeam: (
        { commit, dispatch },
        {
          name,
          owner,

        }
    ) =>
        new Promise((resolve, reject) => {
          api({ requiresAuth: true })
              .post("/v1/teams", {
                name: name,
                owner_id: owner.id,

              })
              .then(
                  async (response) => {


                    const { data } = response.data;
                    commit("setTeamsLoading", true);
                    await dispatch("getTeams");
                    resolve(data);
                    commit("setTeamsLoading", false);
                  },
                  (err) => {
                    commit("setTeamsLoading", false);
                    reject(err);
                  }
              );
        }),
      updateTeam: (
        { commit, dispatch },
        {
          teamId,
          name,
          owner,

        }
    ) =>
        new Promise((resolve, reject) => {
          api({ requiresAuth: true })
              .patch("/v1/teams/" + teamId, {
                name: name,
                owner_id: owner.id,
                  teamId: teamId,
              })
              .then(
                  async (response) => {


                    const { data } = response.data;
                    commit("setTeamsLoading", true);
                    await dispatch("getTeams");
                    resolve(data);
                    commit("setTeamsLoading", false);
                  },
                  (err) => {
                    commit("setTeamsLoading", false);
                    reject(err);
                  }
              );
        }),
  },

};
