<template>
  <div
    class="policy"
    :class="[
      !policyLoading ? `policy--${policyStatusColor}` : null,
      { 'policy--expanded': showMoreDetails },
    ]"
  >
    <Loading v-if="policyLoading" />
    <template v-else>
      <div
        class="row row--policy box box--default"
        :class="{ 'row--active': showMoreDetails }"
      >
        <div
          class="row__data row__data--medium row__data--underline row__data--fixed"
        >
          <span @click="showStatusPopup = true">
            <transition name="fade" mode="in-out">
              <PopupEditer
                v-if="showStatusPopup"
                @togglePopup="showStatusPopup = $event"
                :items="policyStatuses"
                :selected="policyStatus"
                :label="'name'"
                @submit="updatePolicyStatus($event)"
              />
            </transition>
            {{ policyStatus }}
          </span>
        </div>
        <div class="row__data row__data--large row__data--underline">
          <span @click="showPolicyNumberPopup = true">
            <transition name="fade" mode="in-out">
              <PopupEditer
                v-if="showPolicyNumberPopup"
                @togglePopup="showPolicyNumberPopup = $event"
                :selected="policyNumber"
                @submit="updatePolicyNumber($event)"
              />
            </transition>
            {{ policyNumber }}
          </span>
        </div>
        <div class="row__data row__data--large">{{ createdAt }}</div>
        <div class="row__data row__data--large policy__company">
          <span>{{ company }}</span>
        </div>
        <div class="row__data row__data--large">
          {{ branch }}
        </div>
        <div class="row__data row__data--medium row__data--underline">
          <span @click="showAgentPopup = true">
            <transition name="fade" mode="in-out">
              <PopupEditer
                v-if="showAgentPopup"
                @togglePopup="showAgentPopup = $event"
                :items="agents"
                :selected="agent"
                :position="'left'"
                :label="['first_name', 'last_name']"
                @submit="updatePolicyAgent($event)"
              />
            </transition>
            {{ agent }}
          </span>
        </div>
        <div
          class="row__data row__data--medium row__data--half row__data--last"
        >
          <div
            class="row__data row__data--small row__data--pdf icon icon__pdf"
            :class="{ 'icon__pdf--active': policy.policy_files.length }"
            @click="openPolicyFilesModal()"
          ></div>
          <div
            class="row__data row__data--small row__data--more"
            :class="{ 'row__data--rotate': showMoreDetails }"
            @click="showMoreDetails = !showMoreDetails"
          ></div>
        </div>
      </div>
      <div
        class="policy__services"
        :class="{ 'policy__services--expand': showMoreDetails }"
      >
        <div
          class="services"
          ref="services"
          :style="
            showMoreDetails
              ? { maxHeight: `${servicesHeight}px` }
              : { maxHeight: 0 }
          "
          :class="{ 'services--expand': showMoreDetails }"
        >
          <div class="services__no-data" v-if="!services.length">
            No services found
          </div>
          <template v-else>
            <div class="table__header table__header--inner">
              <div class="table__label table__label--medium">
                Insurance Type
              </div>
              <div class="table__label table__label--medium">
                Vertrags Beginn
              </div>
              <div class="table__label table__label--medium">Vertrags Ende</div>
              <div class="table__label table__label--medium">
                Hauptfälligkeit
              </div>
              <div class="table__label table__label--medium">
                Kontrollschild*
              </div>
              <div class="table__label table__label--medium">Nettoprämie</div>
              <div class="table__label table__label--medium">Bruttoprämie</div>
              <div class="table__label table__label--medium">Zahlung</div>
              <div
                class="table__label table__label--medium services__label--delete"
              ></div>
              <div
                class="table__label table__label--medium services__label--delete"
              ></div>
            </div>
            <PolicyService
              v-for="(service, index) in services"
              :key="service.id"
              :service="service"
              :fetchGlobalCustomer="fetchGlobalCustomer"
              :isLastService="index === services.length - 1"
              :totalNetto="policy.total_netto"
              :totalBrutto="policy.total_bruto"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import statics from "@/statics";
import PolicyService from "@/components/AccountDetails/PolicyService";
import PopupEditer from "@/partials/PopupEditer";
import Loading from "@/partials/Loading";
import { formatDisplayDate } from "@/helpers/dateFormatters";
import { fetchGlobalCustomer } from "@/helpers/accountDetailHelpers";
export default {
  name: "Policy",

  components: {
    PolicyService,
    PopupEditer,
    Loading,
  },

  props: {
    policy: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      fetchGlobalCustomer,
      showMoreDetails: false,
      showStatusPopup: false,
      showAgentPopup: false,
      showPolicyNumberPopup: false,
      policyLoading: false,
    };
  },

  computed: {
    ...mapGetters(["policyForms", "language"]),
    policyStatus() {
      return this.policy.policy_status
        ? this.policy.policy_status.name
        : statics.empty;
    },

    policyNumber() {
      return this.policy.policy_number || statics.empty;
    },

    createdAt() {
      return this.policy.created_at
        ? formatDisplayDate(this.policy.created_at)
        : statics.empty;
    },

    agent() {
      return this.policy.agent
        ? `${this.policy.agent.first_name || statics.empty} ${this.policy.agent
            .last_name || statics.empty}`
        : statics.empty;
    },

    company() {
      return this.policy.company
        ? this.policy.company.full_name || statics.empty
        : statics.empty;
    },

    branch() {
      if (this.policy.policy_services.length) {
        return this.policy.policy_services
          .map((service) => {
            if (service.service && service.service.branch_category) {
              return service.service.branch_category.name || statics.empty;
            }
            return statics.empty;
          })
          .join(", ");
      }
      return null;
    },

    services() {
      return this.policy.policy_services.length
        ? this.policy.policy_services
        : [];
    },

    servicesHeight() {
      return [...this.$refs.services.children].reduce(
        (acc, child) => acc + child.clientHeight,
        0
      );
    },

    policyStatusColor() {
      return this.policy.policy_status
        ? statics.policyStatuses[
            statics.policyStatuses.findIndex(
              (status) => this.policy.policy_status.id === status.id
            )
          ].name
        : null;
    },
    policyStatuses() {
      return this.policyForms["policy-statuses"];
    },

    agents() {
      return this.policyForms.agents;
    },
  },

  methods: {
    async updatePolicyStatus(policyStatusId) {
      this.policyLoading = true;
      await this.$store.dispatch("updatePolicyStatus", {
        policyId: this.policy.id,
        policyStatusId,
      });
      await this.fetchGlobalCustomer(this.$route.params.id);
      this.showStatusPopup = false;
      this.policyLoading = false;
    },

    async updatePolicyAgent(policyAgentId) {
      this.policyLoading = true;
      await this.$store.dispatch("updatePolicyAgent", {
        policyId: this.policy.id,
        policyAgentId,
      });
      await this.fetchGlobalCustomer(this.$route.params.id);
      this.showAgentPopup = false;
      this.policyLoading = false;
    },

    async updatePolicyNumber(policyNumber) {
      this.policyLoading = true;
      await this.$store.dispatch("updatePolicyNumber", {
        policyId: this.policy.id,
        policyNumber,
      });
      await this.fetchGlobalCustomer(this.$route.params.id);
      this.showPolicyNumberPopup = false;
      this.policyLoading = false;
    },

    openPolicyFilesModal() {
      this.$store.commit("setPolicyFileId", this.policy.id);
      this.$store.commit("setPolicyFiles", this.policy.policy_files);
      this.$store.commit("setPolicyFilesModalIsTermination", false);
      this.$store.commit("setShowPolicyFilesModal", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  &__header {
    padding-left: 15px;
  }

  &__label {
    padding: 0;
    text-align: left;
  }
}
</style>
