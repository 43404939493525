<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--contact"></div>
      <div class="modal__back" @click="goBack()"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators navigators--company">
          <div
            class="navigator"
            @click="currentPage > 0 ? goTo(0) : null"
            :class="{
              'navigator--active':
                currentPage === 0 ||
                currentPage === 1 ||
                currentPage === 2 ||
                currentPage === 3,
            }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">Details</div>
          </div>
          <div
            class="navigator"
            @click="goTo(1)"
            :class="{
              'navigator--active':
                currentPage === 1 || currentPage === 2 || currentPage === 3,
            }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">Contact</div>
          </div>
          <div
            class="navigator"
            @click="goTo(2)"
            :class="{
              'navigator--active': currentPage === 2 || currentPage === 3,
            }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">Add Service</div>
          </div>
          <div
            class="navigator"
            @click="goTo(3)"
            :class="{ 'navigator--active': currentPage === 3 }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">Logo</div>
          </div>
        </div>

        <form class="add__form">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <transition name="fade" mode="out-in">
                <div class="details" key="0" v-if="currentPage === 0">
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      :class="{ 'input--error': companyNameHasError }"
                      placeholder="Company Name"
                      v-model="company.companyName"
                    />
                    <div class="form__error" v-if="companyNameHasError">
                      Please enter a company name
                    </div>
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Company Full Name"
                      v-model="company.companyFullName"
                    />
                  </div>
                  <div class="form__field">
                    <VSelect
                      class="select select--default"
                      :options="companyTypes"
                      :reduce="(type) => type.id"
                      label="type"
                      placeholder="Company Type"
                      v-model="company.companyType"
                    />
                  </div>
                  <div class="form__field">
                    <div class="details__option">
                      <label class="details__option-name" for="appCheckbox"
                        >View in App</label
                      >
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          id="appCheckbox"
                          :checked="company.viewInApp"
                          class="checkbox__checkbox"
                          @input="formatCheckbox($event.target.checked, 'app')"
                        />
                        <label
                          for="appCheckbox"
                          class="checkbox__label"
                        ></label>
                      </div>
                    </div>
                  </div>
                  <div class="form__field">
                    <div class="details__option">
                      <label class="details__option-name" for="statusCheckbox"
                        >Status</label
                      >
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          id="statusCheckbox"
                          :checked="company.status"
                          class="checkbox__checkbox"
                          @input="
                            formatCheckbox($event.target.checked, 'status')
                          "
                        />
                        <label
                          for="statusCheckbox"
                          class="checkbox__label"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="contact" key="1" v-if="currentPage === 1">
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Address"
                      v-model="company.address"
                    />
                  </div>
                  <div class="form__row">
                    <div
                      class="form__field form__field--double form__field--zip"
                    >
                      <input
                        type="text"
                        class="input input--default"
                        :class="{ 'input--error': zipHasError }"
                        placeholder="Zip"
                        v-model="company.zip"
                      />
                      <div class="form__error" v-if="zipHasError">
                        Zip is invalid
                      </div>
                    </div>
                    <div class="form__field form__field--double">
                      <div
                        class="input input--default input--readonly"
                        v-if="zipCodeData.length === 1"
                      >
                        {{ company.city }}
                      </div>
                      <VSelect
                        v-else-if="zipCodeData.length > 1"
                        class="select select--default"
                        :options="zipCodeData"
                        :reduce="(city) => city.city"
                        label="city"
                        v-model="company.city"
                      />
                      <input
                        v-else
                        type="text"
                        class="input input--default"
                        placeholder="City"
                        v-model="company.city"
                      />
                    </div>
                  </div>
                  <div class="form__field">
                    <VSelect
                      class="select select--default"
                      :options="countries"
                      :reduce="(country) => country.code"
                      label="name_en"
                      placeholder="Country"
                      v-model="company.country"
                    />
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Phone"
                      v-model="company.phone"
                    />
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Fax"
                      v-model="company.fax"
                    />
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Email"
                      v-model="company.email"
                    />
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Homepage"
                      v-model="company.homepage"
                    />
                  </div>
                </div>

                <div class="services" key="2" v-if="currentPage === 2">
                  <div class="form__field">
                    <VSelect
                      class="select select--default"
                      :class="{ 'select--error': servicesHasError }"
                      :options="services"
                      :reduce="(service) => service.id"
                      :label="`long_${language}`"
                      placeholder="Choose Services"
                      @input="addService($event)"
                    />
                    <div class="form__error" v-if="servicesHasError">
                      Please select a service
                    </div>
                  </div>
                  <div class="tabs tabs--company">
                    <div
                      class="tab"
                      v-for="service in company.services"
                      :key="service"
                    >
                      <span>{{
                        services.find((s) => s.id === service)[
                          `long_${language}`
                        ]
                      }}</span>
                      <div
                        class="tab__delete"
                        @click="removeService(service)"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="logo" key="3" v-if="currentPage === 3">
                  <div class="add__avatar">
                    <img
                      :src="
                        companyLogo ||
                          require('../assets/icons/default/Bold 2px-building.svg')
                      "
                      alt=""
                      ref="companyLogo"
                    />
                  </div>
                  <div class="add__upload-text">
                    Upload your Company Image / Logo
                  </div>
                  <div class="add__upload-wrapper">
                    <button
                      type="button"
                      class="button button--default button--upload"
                    >
                      Upload Image
                    </button>
                    <input
                      type="file"
                      class="add__upload"
                      @change="uploadImage($event)"
                    />
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </form>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        type="button"
        class="modal__button modal__button--submit button button--default"
        @click="currentPage !== 3 ? goForward() : createCompany()"
      >
        <span v-if="currentPage === 3">Save</span>
        <span v-else>Next</span>
      </button>
    </div>
  </div>
</template>

<script>
import Loading from "@/partials/Loading";
import { mapGetters } from "vuex";
export default {
  name: "AddCompanyContactModal",

  components: {
    Loading,
  },

  data() {
    return {
      currentPage: 0,
      formLoading: false,
      isRequesting: false,

      company: {
        companyName: "",
        companyFullName: "",
        companyType: "",
        viewInApp: 0,
        status: 0,
        address: "",
        zip: "",
        city: "",
        country: "",
        phone: "",
        fax: "",
        email: "",
        homepage: "",
        services: [],
      },

      file: "",
      companyLogo: "",

      companyNameHasError: false,
      servicesHasError: false,
      zipHasError: false,
    };
  },

  watch: {
    "company.companyName"(value) {
      if (value) {
        this.companyNameHasError = false;
      } else {
        this.companyNameHasError = true;
      }
    },

    "company.services"(value) {
      if (value.length) {
        this.servicesHasError = false;
      } else {
        this.servicesHasError = true;
      }
    },

    async "company.zip"(zip) {
      if (zip >= 1000 && zip <= 9999 && zip) {
        this.zipHasError = false;
        await this.loadCities(zip);
      } else {
        this.zipHasError = true;
      }
    },
  },

  computed: {
    ...mapGetters(["companyForms", "zipCodeData", "contactToEdit", "language"]),

    companyTypes() {
      return this.companyForms["company-type"];
    },

    services() {
      return this.companyForms.services;
    },

    countries() {
      return this.companyForms.countries;
    },
  },

  async created() {
    this.formLoading = true;
    await this.$store.dispatch("getCompanyForms");
    if (Object.keys(this.contactToEdit).length) {
      this.loadFieldsToEdit();
    }
    this.formLoading = false;
  },

  destroyed() {
    this.$store.commit("setContactToEdit", {});
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddCompanyContactModal", false);
    },

    loadFieldsToEdit() {
      const {
        name,
        full_name,
        is_app,
        status,
        address,
        zip,
        country_code,
        phone,
        fax,
        email,
        web,
        services,
        branch_id,
        logo,
      } = this.contactToEdit;
      this.company.companyName = name;
      this.company.companyFullName = full_name;
      this.company.viewInApp = is_app;
      this.company.status = status;
      this.company.address = address;
      this.company.zip = zip;
      this.company.country = country_code;
      this.company.phone = phone;
      this.company.fax = fax;
      this.company.email = email;
      this.company.homepage = web;
      this.company.services = services.map((service) => service.id);
      this.company.companyType = branch_id;
      this.companyLogo = logo ? logo.url : "";
    },

    goTo(page) {
      if (page === 1 || page === 2) {
        this.validateCompanyDetails((state) => {
          if (state) {
            this.currentPage = page;
          }
        });
      } else if (page === 3) {
        this.validateCompanyDetails((detailsState) => {
          this.validateCompanyServices((servciesState) => {
            if (servciesState && detailsState) {
              this.currentPage = page;
            }
          });
        });
      } else {
        this.currentPage = page;
      }
    },

    goForward() {
      if (this.currentPage < 3) {
        if (this.currentPage === 0 || this.currentPage === 1) {
          this.validateCompanyDetails((state) => {
            if (state) {
              this.currentPage++;
            }
          });
        } else if (this.currentPage === 2) {
          this.validateCompanyServices((state) => {
            if (state) {
              this.currentPage++;
            }
          });
        }
      }
    },

    validateCompanyDetails(callback) {
      const { companyName } = this.company;
      if (!companyName) {
        this.companyNameHasError = true;
      }

      if (!this.companyNameHasError) {
        callback(true);
      }
    },

    validateCompanyServices(callback) {
      const { services } = this.company;

      if (!services.length) {
        this.servicesHasError = true;
      }

      if (!this.servicesHasError) {
        callback(true);
      }
    },

    addService(serviceId) {
      if (!this.company.services.includes(serviceId)) {
        this.company.services = [...this.company.services, serviceId];
      }
    },
    removeService(serviceId) {
      const serviceIndex = this.company.services.findIndex(
        (id) => id === serviceId
      );
      if (serviceIndex !== -1) {
        this.company.services.splice(serviceIndex, 1);
      }
    },

    formatCheckbox(state, type) {
      state = state ? 1 : 0;
      if (type === "app") {
        this.company.viewInApp = state;
      } else if (type === "status") {
        this.company.status = state;
      }
    },

    async loadCities(value) {
      await this.$store.dispatch("getZipCodeData", value);
      if (this.zipCodeData.length) {
        this.company.city = this.zipCodeData[0].city;
      } else {
        this.company.city = "";
      }
    },

    uploadImage(event) {
      this.file = event.target.files[0];
      this.companyLogo = URL.createObjectURL(this.file);
    },

    async createCompany() {
      this.isRequesting = true;
      this.validateCompanyDetails(async (detailsState) => {
        this.validateCompanyServices(async (servicesState) => {
          if (detailsState && servicesState) {
            this.company.file = this.file;
            if (!Object.keys(this.contactToEdit).length) {
              await this.$store.dispatch("addCompany", this.company);
            } else {
              try {
                const { data } = await this.$store.dispatch("updateCompany", {
                  ...this.company,
                  companyId: this.contactToEdit.id,
                  name: this.company.companyName,
                  branchId: this.company.companyType,
                });
                const { data: company } = data;
                this.$store.commit("setSelectedCompany", company);
                this.$store.commit("setToast", {
                  display: true,
                  type: "success",
                  message: "Company Contact updated successfully.",
                });
              } catch (err) {
                this.closeModal();
                console.error(err);
              }
            }
            await this.$store.dispatch("getCompanies");
            this.closeModal();
            this.isRequesting = false;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-name {
      color: var(--text-2);
      font-family: var(--muli-text-semi-bold);
      font-size: var(--x-small);
    }
  }
}

.form__field--zip {
  margin-right: 5px;
}
</style>
